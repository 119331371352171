//------------------Basic imports---------------------------
import React from 'react'
import MainRouter from './route'
import { CustomAlert } from './components'

function App() {
  return (
    <>
      <CustomAlert />
      <MainRouter />
    </>
  )
}

export default App
