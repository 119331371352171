import apiClient from './apiClient'

class AdminsApi {
  static getAdmins(page, limit = 20) {
    return apiClient
      .get(`/admins/?page=${page}&limit=${limit}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(({ data }) => data.data)
  }
  static createAdmin(data) {
    return apiClient
      .post(`/admins/`, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(({ data }) => data.data)
  }
  static deleteAdmin(id) {
    return apiClient
      .delete(`/admins/${id}/`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(({ data }) => data.data)
  }
}

export default AdminsApi
