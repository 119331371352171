//---------------------Basic imports-----------------------
import React from 'react'
import './BanModal.scss'
import { Modal } from '..'

const BanModal = ({ show, close, data, type, onClick, title, text }) => {
  return (
    <Modal className='reviewsModal1' visible={show} onClosePopup={close}>
      <div>
        <div>
          <div className='titleBan'>{title}</div>
          <div className='textBan'>{text}</div>
        </div>
        <div className='btnRow'>
          <div onClick={close} className='btn2'>
            Cancel
          </div>
          <div onClick={onClick} className='btn1'>
            {type}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default BanModal
