//----------------------Basic imports------------------
import React from 'react'

//---------------------Libraries-----------------------
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'

const CustomAlert = () => {
  const { message, show } = useSelector((state) => state.alertReducer)

  React.useEffect(() => {
    if (message && message.trim() !== '') toast(message)
  }, [show, message])
  return <ToastContainer newestOnTop />
}

export default CustomAlert
