import React from 'react'
import { Redirect } from 'react-router-dom'
import { getToken } from '../utils/localStorage'
import ROUTES from './routes'

const PrivateRoute = ({ children }) => {
  const token = getToken()
  console.log('PrivateRoute', token)
  if (!token) {
    return <Redirect to={ROUTES.signIn} />
  }
  return children
}

export default PrivateRoute
