//---------------------Basic imports-----------------------
import React from 'react'
import './Button.scss'

//---------------------Libraries-----------------------
import clsx from 'clsx'

const Button = ({
  children,
  disabled = false,
  onClick,
  className,
  type = 'button',
}) => {
  //---------------------Layout-----------------------

  return (
    <button
      type={type}
      className={clsx('button', className && className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
