//---------------------Basic imports-----------------------
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import UsersAPI from '../../APIs/users'
import ICONS from '../../assets/icons'
import {
  DELETE_USER,
  GET_USERS,
  SET_COUNT,
} from '../../redux/actions/actionTypes'
import { getToken } from '../../utils/localStorage'
import BanModal from '../BanModal'
import DeleteModal from '../DeleteModal'
import moment from 'moment'
import styles from './UserInformation.module.scss'

const UserInformation = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [ban, setBan] = React.useState(false)
  const [ban2, setBan2] = React.useState(false)
  const {
    photos,
    name,
    city,
    id,
    isBaned,
    isHosting,
    eyes,
    bodyType,
    ethnicity,
    gender,
    height,
    hair,
    position,
    relationships,
    age,
    weight,
    size,
    tags,
    hivStatus,
    vaccine,
    monkeyPox,
    lookingFor,
    personality,
    kinks,
    meetAt,
    hobbies,
    acceptNSFWPics,
    links,
    premium,
  } = location.state.data

  const { search } = useSelector((state) => state.expertsReducer)
  const { pagination } = useSelector((state) => state.usersReducer)
  const [banned, setBanned] = useState(isBaned)
  const [localData, setLocalData] = useState(premium?.date)

  const getUsers = async () => {
    const res = await UsersAPI.getUsers(search, pagination)
    dispatch({ type: GET_USERS, payload: res.rows })
    dispatch({ type: SET_COUNT, payload: res.count })
  }

  console.log('location.state.data', location.state.data)

  const restrict = async () => {
    const token = getToken()
    await UsersAPI.banUser(id, token)
    setBanned((prev) => !prev)
    setBan(false)
    getUsers()
  }

  const deleteUser = async () => {
    await UsersAPI.deleteUser(id)
    dispatch({ type: DELETE_USER, payload: id })
    getUsers()
    history.goBack()
  }

  const deletePhoto = async (photoId) => {
    const res = await UsersAPI.deleteUsersPhoto(id, photoId)
    getUsers()
    history.push({
      pathname: `/users/all/${id}`,
      state: { data: res },
    })
  }

  const axiosPremiumUser = async () => {
    const res = await UsersAPI.premiumUser(id)
    getUsers()
    if (res.premium.isActive) {
      setLocalData(res?.premium?.date)
    } else {
      setLocalData(null)
    }
  }

  return (
    <>
      <div className={styles.PROs}>
        <div className={styles.header}>
          <div
            className={styles.headerLeft}
            onClick={() => history.push('/users/all/')}
          >
            <div>{ICONS.utils.back}</div>
            <p className={styles.back}>Back to all users</p>
          </div>
          <div className='myBtnRow'>
            <button
              onClick={() => setBan(true)}
              type='button'
              className={banned ? 'myClassBunButton' : 'myClassButton'}
            >
              {banned ? 'Unban' : 'Ban'}
            </button>
            <div onClick={() => setBan2(true)} className='myClassButton2'>
              Delete
            </div>
          </div>
        </div>
        <div className={styles.mainInfo}>
          <div className={styles.mainInfo__left}>
            <div className={styles.mainInfo__photoContainer}>
              {photos.length ? (
                <img
                  alt='main img'
                  src={photos.find((photo) => photo.isMain).photo}
                  className={styles.mainInfo__mainPhoto}
                />
              ) : (
                <div className={styles.mainInfo__noPhoto}>
                  <div>{ICONS.utils.noPhoto}</div>
                </div>
              )}
              {name ? (
                <p className={styles.mainInfo__name}>{name}</p>
              ) : (
                <p className={styles.mainInfo__name}>Zhulik :)</p>
              )}
            </div>

            <p className={styles.mainInfo__title}>Stats</p>

            <div className={styles.mainInfo__info}>
              <div className={styles.mainInfo__col}>
                <p className={styles.mainInfo__subtitle}>City</p>
                {city ? (
                  <p className={styles.mainInfo__description}>{city}</p>
                ) : (
                  <p className={styles.mainInfo__description}>No city</p>
                )}
                <p className={styles.mainInfo__subtitle}>Host</p>
                <p
                  className={
                    isHosting
                      ? styles.mainInfo__descriptionBlue
                      : styles.mainInfo__descriptionRed
                  }
                >
                  {isHosting ? 'YES' : 'NO'}
                </p>
                <p className={styles.mainInfo__subtitle}>Eye color</p>
                {eyes ? (
                  <p className={styles.mainInfo__description}>{eyes}</p>
                ) : (
                  <p className={styles.mainInfo__description}>No eyes</p>
                )}
                <p className={styles.mainInfo__subtitle}>Body type</p>
                {bodyType.length ? (
                  <p className={styles.mainInfo__description}>
                    {bodyType.map((type) => type).join(', ')}
                  </p>
                ) : (
                  <p className={styles.mainInfo__description}>No body type</p>
                )}
                <p className={styles.mainInfo__subtitle}>Ethnicity</p>
                {ethnicity ? (
                  <p className={styles.mainInfo__description}>{ethnicity}</p>
                ) : (
                  <p className={styles.mainInfo__description}>No ethnicity</p>
                )}
              </div>

              <div className={styles.mainInfo__col}>
                <p className={styles.mainInfo__subtitle}>Gender</p>
                {gender ? (
                  <p className={styles.mainInfo__description}>{gender}</p>
                ) : (
                  <p className={styles.mainInfo__description}>No gender</p>
                )}
                <p className={styles.mainInfo__subtitle}>Height</p>
                {height.type ? (
                  <p
                    className={styles.mainInfo__description}
                  >{`${height.cm} cm / ${height.in} in`}</p>
                ) : (
                  <p className={styles.mainInfo__description}>No height</p>
                )}
                <p className={styles.mainInfo__subtitle}>Hair color</p>
                {hair ? (
                  <p className={styles.mainInfo__description}>{hair}</p>
                ) : (
                  <p className={styles.mainInfo__description}>No hair</p>
                )}
                <p className={styles.mainInfo__subtitle}>Position</p>
                {position.length ? (
                  <p className={styles.mainInfo__description}>
                    {' '}
                    {position.map((type) => type).join(', ')}
                  </p>
                ) : (
                  <p className={styles.mainInfo__description}>No position</p>
                )}
                <p className={styles.mainInfo__subtitle}>Relationship status</p>
                {relationships ? (
                  <p className={styles.mainInfo__description}>
                    {relationships}
                  </p>
                ) : (
                  <p className={styles.mainInfo__description}>
                    No relationships
                  </p>
                )}
              </div>

              <div className={styles.mainInfo__col}>
                <p className={styles.mainInfo__subtitle}>Age</p>
                {age ? (
                  <p className={styles.mainInfo__description}>{age}</p>
                ) : (
                  <p className={styles.mainInfo__description}>No age</p>
                )}
                <p className={styles.mainInfo__subtitle}>Weight</p>
                {weight.type ? (
                  <p
                    className={styles.mainInfo__description}
                  >{`${weight.kg} kg / ${weight.lbs} lbs`}</p>
                ) : (
                  <p className={styles.mainInfo__description}>No weight</p>
                )}
                <p className={styles.mainInfo__subtitle}>Body hair</p>
                {hair ? (
                  <p className={styles.mainInfo__description}>{hair}</p>
                ) : (
                  <p className={styles.mainInfo__description}>No hair</p>
                )}
                <p className={styles.mainInfo__subtitle}>Size</p>
                {size.type ? (
                  <p
                    className={styles.mainInfo__description}
                  >{`${size.cm?.toFixed(1)} cm / ${size.in} in`}</p>
                ) : (
                  <p className={styles.mainInfo__description}>No size</p>
                )}
                <p className={styles.mainInfo__subtitle}>Identity</p>
                {tags.length ? (
                  <p className={styles.mainInfo__description}>
                    {' '}
                    {tags.map((item) => item).join(', ')}
                  </p>
                ) : (
                  <p className={styles.mainInfo__description}>No identity</p>
                )}
              </div>
            </div>
            <p className={styles.mainInfo__title}>Health</p>

            <div className={styles.mainInfo__info}>
              <div className={styles.mainInfo__col}>
                <p className={styles.mainInfo__subtitle}>HIV Status</p>
                {hivStatus ? (
                  <p className={styles.mainInfo__description}>{hivStatus}</p>
                ) : (
                  <p className={styles.mainInfo__description}>No HIV Status</p>
                )}
              </div>

              <div className={styles.mainInfo__col}>
                <p className={styles.mainInfo__subtitle}>Covid-19</p>
                {vaccine ? (
                  <p className={styles.mainInfo__description}>{vaccine}</p>
                ) : (
                  <p className={styles.mainInfo__description}>No vaccine</p>
                )}
              </div>

              <div className={styles.mainInfo__col}>
                <p className={styles.mainInfo__subtitle}>Monkeypox</p>
                {monkeyPox ? (
                  <p className={styles.mainInfo__description}>{monkeyPox}</p>
                ) : (
                  <p className={styles.mainInfo__description}>No monkeypox</p>
                )}
              </div>
            </div>

            <p className={styles.mainInfo__title}>Expectations</p>

            <div className={styles.mainInfo__info}>
              <div className={styles.mainInfo__col}>
                <p className={styles.mainInfo__subtitle}>Looking for</p>
                {lookingFor.length ? (
                  <p className={styles.mainInfo__description}>
                    {lookingFor.map((item) => item).join(', ')}
                  </p>
                ) : (
                  <p className={styles.mainInfo__description}>No looking for</p>
                )}
                <p className={styles.mainInfo__subtitle}>Personality</p>
                {personality.length ? (
                  <p className={styles.mainInfo__description}>
                    {personality.map((item) => item).join(', ')}
                  </p>
                ) : (
                  <p className={styles.mainInfo__description}>No personality</p>
                )}
              </div>

              <div className={styles.mainInfo__col}>
                <p className={styles.mainInfo__subtitle}>Kinks</p>
                {kinks.length ? (
                  <p className={styles.mainInfo__description}>
                    {kinks.map((item) => item).join(', ')}
                  </p>
                ) : (
                  <p className={styles.mainInfo__description}>No kinks</p>
                )}
                <p className={styles.mainInfo__subtitle}>Meet at</p>
                {meetAt.length ? (
                  <p className={styles.mainInfo__description}>
                    {meetAt.map((item) => item).join(', ')}
                  </p>
                ) : (
                  <p className={styles.mainInfo__description}>No meet at</p>
                )}
              </div>

              <div className={styles.mainInfo__col}>
                <p className={styles.mainInfo__subtitle}>Hobbies</p>
                {hobbies.length ? (
                  <p className={styles.mainInfo__description}>
                    {hobbies.map((item) => item).join(', ')}
                  </p>
                ) : (
                  <p className={styles.mainInfo__description}>No hobbies</p>
                )}
                <p className={styles.mainInfo__subtitle}>NSFW Pics</p>
                {acceptNSFWPics ? (
                  <p className={styles.mainInfo__description}>
                    {acceptNSFWPics}
                  </p>
                ) : (
                  <p className={styles.mainInfo__description}>No NSFW Pics</p>
                )}
              </div>
            </div>
            <p className={styles.mainInfo__subtitle}>Social Links</p>
            {links?.twitter ? (
              <p className={styles.mainInfo__link}>
                https://twitter.com/{links?.twitter}
              </p>
            ) : (
              <p className={styles.mainInfo__link}>No twitter link</p>
            )}
            {links?.facebook ? (
              <p className={styles.mainInfo__link}>
                https://facebook.com/{links?.facebook}
              </p>
            ) : (
              <p className={styles.mainInfo__link}>No facebook link</p>
            )}
            {links?.instagram ? (
              <p className={styles.mainInfo__link}>
                https://instagram.com/{links?.instagram}
              </p>
            ) : (
              <p className={styles.mainInfo__link}>No instagram link</p>
            )}
            {links?.tikTok ? (
              <p className={styles.mainInfo__link}>
                https://tiktok.com/{links?.tikTok}
              </p>
            ) : (
              <p className={styles.mainInfo__link}>No tiktok link</p>
            )}
            {links?.xboxLive ? (
              <p className={styles.mainInfo__link}>
                https://xboxlive.com/{links?.xboxLive}
              </p>
            ) : (
              <p className={styles.mainInfo__link}>No xboxLive link</p>
            )}
            {links?.psn ? (
              <p className={styles.mainInfo__link}>
                https://psn.com/{links?.psn}
              </p>
            ) : (
              <p className={styles.mainInfo__link}>No psn link</p>
            )}
            {links?.switch ? (
              <p className={styles.mainInfo__link}>
                https://switch.com/{links?.switch}
              </p>
            ) : (
              <p className={styles.mainInfo__link}>No switch link</p>
            )}
            {links?.stream ? (
              <p className={styles.mainInfo__link}>
                https://stream.com/{links?.stream}
              </p>
            ) : (
              <p className={styles.mainInfo__link}>No stream link</p>
            )}
          </div>

          <div className={styles.mainInfo__right}>
            <p className={styles.mainInfo__subtitle}>Photo</p>
            <div className={styles.mainInfo__photosWrapper}>
              {photos.map((photo) => (
                <div className={styles.mainInfo__photosContainer}>
                  <img
                    alt='user'
                    src={photo.photo}
                    className={styles.mainInfo__photo}
                  />
                  <div
                    className={styles.delete}
                    onClick={() => deletePhoto(photo.id)}
                  >
                    <p className={styles.deletePlus}>+</p>
                  </div>
                </div>
              ))}
              <div className={styles.mainInfo__arrayWrapper}>
                {Array(6)
                  .fill(1)
                  .map((_, index) => (
                    <div className={styles.mainInfo__arrayPhoto}>
                      <div>{ICONS.utils.noPhoto}</div>
                    </div>
                  ))}
                <div className={styles.mainInfo__premium}>
                  <div className={styles.mainInfo__premiumWrapper}>
                    <p className={styles.mainInfo__subtitle}>Give premium</p>
                    <input
                      type='checkbox'
                      defaultChecked={premium?.isActive}
                      onChange={axiosPremiumUser}
                      className={styles.mainInfo__checkbox}
                    />
                  </div>
                  <p className={styles.mainInfo__subtitle}>
                    {localData
                      ? `Premium given on: ${moment(localData).format(
                          'MM/DD/YYYY'
                        )}`
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BanModal
        show={ban}
        close={() => setBan(false)}
        title={isBaned ? 'Unban this user?' : 'Ban this user?'}
        type={isBaned ? 'Unban' : 'Ban'}
        onClick={restrict}
        text={
          isBaned
            ? 'Are you sure you want to add access to the app for this user?'
            : 'Are you sure you want to restrict access to the app for this user?'
        }
      />
      <DeleteModal
        show={ban2}
        close={() => setBan2(false)}
        onClick={deleteUser}
      />
    </>
  )
}

export default UserInformation
