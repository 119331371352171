import apiClient from './apiClient'
import { getToken } from '../utils/localStorage'

class UsersAPI {
  static token = getToken(); // Make token static

  static getUsers(q, page, limit = 20) {
    console.log("token is",this.token);
    return apiClient
      .get(`/users/?q=${q}&page=${page}&limit=${limit}&sort=-createdAt`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(({ data }) => data.data)
  }

  static deleteUser(id) {
    return apiClient
      .delete(`/users/${id}/`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(({ data }) => data.data)
  }

  static deleteUsersPhoto(id, photoId) {
    return apiClient
      .delete(`/users/${id}/photo/${photoId}/`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(({ data }) => data.data)
  }

  static banUser(id, token) {
    return apiClient
      .put(
        `/users/${id}/ban/`,
        {},
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => data.data)
  }

  static unbanUser(id) {
    return apiClient
      .put(
        `/users/${id}/unban/`,
        {},
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
          },
        }
      )
      .then(({ data }) => data.data)
  }
  static premiumUser(id) {
    return apiClient
      .put(
        `/users/${id}/premium/`,
        {},
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
          },
        }
      )
      .then(({ data }) => data.data)
  }
}

export default UsersAPI
