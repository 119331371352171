// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotFound_main__1jsH4 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  max-height: calc(100% - 135px);\n}", "",{"version":3,"sources":["webpack://src/pages/NotFound/NotFound.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,8BAAA;AADF","sourcesContent":["@import '../../scss/colors.scss';\n\n.main {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  max-height: calc(100% - 135px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "NotFound_main__1jsH4"
};
export default ___CSS_LOADER_EXPORT___;
