import * as yup from "yup";
import validationValues from "../values";

const addCategorySchema = yup.object().shape({
  subCategories: validationValues.subCategories,
  categoryTitle: validationValues.categoryTitle,
  photo: validationValues.photo,
});

export default addCategorySchema;
