import React from 'react'
import styles from './Users.module.scss'

import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'
import { SET_PAGINATION } from '../../redux/actions/actionTypes'

import ROUTES from '../../route/routes'

import AllUsers from './AllUsers'

import { SearchInput } from '../../components'

import { searchExpertAction } from '../../redux/actions/expertsActions'
import PaginationRight from '../../assets/icons/utils/paginationRight'
import PaginationLeft from '../../assets/icons/utils/paginationLeft'
import NotFound from '../../pages/NotFound'

const Users = () => {
  const dispatch = useDispatch()
  const { search } = useSelector((state) => state.expertsReducer)
  const { count, pagination } = useSelector((state) => state.usersReducer)

  const location = useLocation().pathname

  const request = location.includes(ROUTES.users.requests)

  const infoTabs = [
    'Registration date ',
    'User name',
    'Phone',
    'Paid account',
    '',
  ]

  const addPage = () => {
    if (pagination < Math.ceil(count / 20)) {
      dispatch({ type: SET_PAGINATION, payload: pagination + 1 })
    } else {
      return
    }
  }

  const prevPage = () => {
    if (pagination > 1) {
      dispatch({ type: SET_PAGINATION, payload: pagination - 1 })
    } else {
      return
    }
  }

  return (
    <>
      <div className={styles.PROs}>
        <div className={styles.PROs__header}>
          <div className={styles.PROs__filters}>
            <div className={styles.bigTitle}>Manage Users</div>
            <div className={styles.searchBlock}>
              <SearchInput
                placeholder='Search'
                value={search}
                onChange={(value) => {
                  dispatch(searchExpertAction(value))
                  dispatch({ type: SET_PAGINATION, payload: 1 })
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.PROs__infoBar}>
          {infoTabs.map((item, index) => (
            <p className={clsx(request && 'hide')} key={index}>
              {item}
            </p>
          ))}
        </div>
        <Switch>
          <Route exact path={ROUTES.users.page}>
            <Redirect to={ROUTES.users.all} />
          </Route>
          <Route path={ROUTES.users.all} component={AllUsers} />
          <Route path={ROUTES.notFound} component={NotFound} />
        </Switch>

        <div className={styles.pagination}>
          <div className={styles.paginationArrow} onClick={prevPage}>
            {PaginationLeft(
              pagination > 1 ? '#404247' : 'rgba(43, 45, 51, 0.24)'
            )}
          </div>
          <p className={styles.paginationCurrent}>{pagination}</p>
          <p>of</p>
          <p className={styles.paginationAll}>{Math.ceil(count / 20)}</p>
          <div className={styles.paginationArrow} onClick={addPage}>
            {PaginationRight(
              pagination < Math.ceil(count / 20)
                ? '#404247'
                : 'rgba(43, 45, 51, 0.24)'
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Users
