import apiClient from './apiClient'

const rapams = (pageType, tags, time) => {
  if (pageType && tags && time) {
    return `?pageType=${pageType}&tags=${tags}&time=${time}`
  }
  if (pageType && tags) {
    return `?pageType=${pageType}&tags=${tags}`
  }
  if (pageType && time) {
    return `?pageType=${pageType}&time=${time}`
  }
  if (tags && time) {
    return `?tags=${tags}&time=${time}`
  }
  if (pageType) {
    return `?pageType=${pageType}`
  }
  if (tags) {
    return `?tags=${tags}`
  }
  if (time) {
    return `?time=${time}`
  }
  return ''
}

class AdsAPI {
  static savePhoto(fd) {
    return apiClient
      .post('/files/upload/', fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer uNbkL6KLxwethVYLDKoMGgXgtNHoFcOY`,
        },
      })
      .then(({ data }) => data.data)
  }

  static saveAds(data) {
    return apiClient
      .post('/ads/', data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(({ data }) => data.data)
  }

  static deleteAds(data) {
    return apiClient
      .delete(`/ads/`, {
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(({ data }) => data.data)
  }

  static editAds(id, data) {
    return apiClient
      .put(`/ads/${id}`, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(({ data }) => data.data)
  }

  static getAds(pageType, tags, time) {
    return apiClient
      .get(`/ads/${rapams(pageType, tags, time)}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(({ data }) => data.data)
  }

  // static getAllAds(tags, time) {
  //   return apiClient
  //     .get(`/ads/?tags=${tags}&time=${time}`, {
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyZmUyYTkxMjEwY2MzZWJlMGRiNWY2NCIsImlhdCI6MTY2MTkzNDQ3OH0.WcsiysRB7yZ8Trk_5HnZkmJqSm1p-pnV9quHMGmxK-c`,
  //       },
  //     })
  //     .then(({ data }) => data.data)
  // }
}

export default AdsAPI
