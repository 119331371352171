const PaginationRight = (color = '#404247') => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.71 23.71L12.29 22.29L18.59 16L12.29 9.71004L13.71 8.29004L21.41 16L13.71 23.71Z'
      fill={color}
    />
  </svg>
)

export default PaginationRight
