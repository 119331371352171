//---------------------Basic imports-----------------------
import React from 'react'
import './UsersCard.scss'

//--------------------Libraries----------------------------
import clsx from 'clsx'
import { useHistory } from 'react-router'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import BanModal from '../BanModal'
import DeleteModal from '../DeleteModal'
import UsersAPI from '../../APIs/users'
import { DELETE_USER } from '../../redux/actions/actionTypes'
import { getToken } from '../../utils/localStorage'
import images from '../../assets'

const UsersCard = ({ data, getUsers }) => {
  //---------------------Data-----------------------
  const history = useHistory()
  const [elit] = React.useState(false)
  const [ban, setBan] = React.useState(false)
  const [ban2, setBan2] = React.useState(false)
  const { createdAt, photos, name, phone, id, isBaned } = data
  const dispatch = useDispatch()

  //---------------------Hooks--------------------

  const [state] = React.useState({
    showMore: false,
    showReviews: false,
    showProStatusControls: false,
    loading: false,
  })

  //---------------------State-----------------------

  const { showMore } = state

  const restrict = async () => {
    const token = getToken()
    await UsersAPI.banUser(id, token)
    setBan(false)
    getUsers()
  }

  const deleteUser = async () => {
    await UsersAPI.deleteUser(id)
    dispatch({ type: DELETE_USER, payload: id })
    getUsers()
  }

  return (
    <>
      <li className={clsx('PROsCard', showMore && 'PROsCard-active')}>
        <div className='PROsCard__top'>
          <div className='PROsCard__topBlock'>
            {moment(createdAt).format('MM/DD/YYYY')}
          </div>
          <div className='PROsCard__topBlock'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {photos.length ? (
                <img
                  alt='avatar'
                  src={photos.find((p) => p.isMain)?.photo}
                  className='userPhoto'
                />
              ) : (
                <img
                  alt='avatar'
                  src={images.noPhoto}
                  className='userNoPhoto'
                />
              )}
              <div className='userBlock'>
                <div className='userName'>{name}</div>
                <div
                  className='userNameView'
                  onClick={() =>
                    history.push({
                      pathname: `/users/all/${id}`,
                      state: { data },
                    })
                  }
                >
                  View all information
                </div>
              </div>
            </div>
          </div>

          <div className='PROsCard__topBlock'>{phone}</div>
          <div className='PROsCard__topBlock'>
            {data.premium.isActive ? 'Yes' : 'No'}
          </div>
          <div className='myBtnRow'>
            <button
              onClick={() => setBan(true)}
              type='button'
              className={isBaned ? 'myClassBunButton' : 'myClassButton'}
            >
              {isBaned ? 'Unban' : 'Ban'}
            </button>
            <div onClick={() => setBan2(true)} className='myClassButton2'>
              Delete
            </div>
          </div>
        </div>
        {elit ? (
          <div className='userRowCol'>
            <div className='userCol'>
              <div className='title2'>Tags:</div>
              <input className='input2' placeholder='Tags:'></input>
              <div className='title2'>City</div>
              <input className='input2' placeholder='City'></input>
              <div className='title2'>Position</div>
              <input className='input2' placeholder='Position'></input>
              <div className='title2'>Uploaded photos</div>
              <img
                alt='avatar'
                src={photos.find((p) => p.isMain)?.photo}
                className='userPhoto2'
              />
            </div>
            <div className='userCol'>
              <div className='title2'>HIV Status</div>
              <input className='input2' placeholder='HIV Status'></input>
              <div className='title2'>Categories</div>
              <input className='input2' placeholder='Categories'></input>
              <div className='title2'>Post ID </div>
              <input className='input2' placeholder='Post ID '></input>
            </div>
          </div>
        ) : null}
      </li>

      <BanModal
        show={ban}
        close={() => setBan(false)}
        data={data}
        title={isBaned ? 'Unban this user?' : 'Ban this user?'}
        type={isBaned ? 'Unban' : 'Ban'}
        onClick={restrict}
        text={
          isBaned
            ? 'Are you sure you want to add access to the app for this user?'
            : 'Are you sure you want to restrict access to the app for this user?'
        }
      />
      <DeleteModal
        show={ban2}
        close={() => setBan2(false)}
        data={data}
        onClick={deleteUser}
      />
    </>
  )
}

export default UsersCard
