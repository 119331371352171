//---------------------Basic imports-----------------------
import React, { useState } from 'react'
import './Input.scss'
import Pensil from '../../assets/icons/utils/pensil'
import Delete from '../../assets/icons/utils/delete'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import DeleteModal from '../DeleteModal'
import { AdsAPI } from '../../APIs'
import ICONS from '../../assets/icons'

const Cart = ({ item, getAdsAxios, selectTotal }) => {
  const history = useHistory()

  //---------------------Handlers-----------------------
  const [show, setShow] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteArray, setDeleteArray] = useState([])
  const [checkbox, setCheckbox] = useState(false)

  const editAds = () => {
    history.push('/ads/create', { item })
  }

  const select = (id) => {
    if (deleteArray?.findIndex((c) => c === id) === -1) {
      setDeleteArray((prev) => [...prev, id])
    } else {
      return
    }
  }

  const deleteAds = async () => {
    try {
      const data = {
        ids: deleteArray,
      }
      await AdsAPI.deleteAds(data)
      setOpenDeleteModal(false)
      getAdsAxios()
    } catch (e) {
      console.log('e', e)
    }
  }

  const severalFunc = () => {
    selectTotal(item.id)
    setCheckbox(true)
  }

  console.log('item', item)

  return (
    <>
      <div className={'cartBorder'}>
        <div className={'cartCase'}>
          <div className='cartImageContainer'>
            <div className='checkboxContainer'>
              {checkbox ? (
                <div
                  className='checkboxActive'
                  onClick={() => {
                    selectTotal(item.id)
                    setCheckbox(false)
                  }}
                >
                  <div>{ICONS.checkboxActive}</div>
                </div>
              ) : (
                <div className='checkboxDisactive' onClick={severalFunc} />
              )}
            </div>
            <img className={'cartImage'} src={item.image} alt={item.image} />
          </div>
          <div className={'cartName'}>
            <div className={'cartNameTitle'}>{item.title}</div>
            <div
              className={'cartNameMore'}
              onClick={() => setShow((prev) => !prev)}
            >
              View all information
            </div>
          </div>
          <div className={'cartNameBefore'}>
            <p>{item.description}</p>
            <div>
              <p className='impressionsText'>Clicks: {item.clicks}</p>
              <p className='impressionsText'>
                Impressions: {item.alreadyImpressions} / {item.maxImpressions}
              </p>
              <p className='impressionsText'>
                CTR:{' '}
                {((item.clicks / item.alreadyImpressions) * 100).toFixed(0)}%
              </p>
            </div>
          </div>
          <div className={'cartNameCategory'}>
            <div className={'cartNameCategoryBlock'}></div>
            <p className={'cartNameCategoryText'}>
              {item.tags.map((i) => i).join(', ')}
            </p>
          </div>
          <div className={'cartNameDate'}>
            {moment(item.createdAt).format('MM/DD/YYYY LT').toLowerCase()}
          </div>
          <div className={'cartNameButtons'}>
            <div className={'castomBtn'} onClick={editAds}>
              <Pensil />
            </div>
            <div
              className={'castomBtn2'}
              onClick={() => {
                select(item.id)
                setOpenDeleteModal(true)
              }}
            >
              <Delete />
            </div>
          </div>
        </div>
        {show ? (
          <div className={'moreCase'}>
            <div className={'moreCasePhoto'}>
              <div className={'moreCaseTitle'}>Photo</div>
              <div className={'moreCasePhotoBg'}>
                <img
                  className={'cartImageInfo'}
                  src={item.image}
                  alt={item.image}
                />
              </div>
            </div>
            <div className={'moreCaseBlock'}>
              <div className={'moreCaseTitle'}>Title</div>
              <div className={'moreCaseText'}>{item.title}</div>
              <div className={'moreCaseTitle'}>Post description</div>
              <div className={'moreCaseText'}>{item.description}</div>
              <div className={'moreCaseTitle'}>Post type</div>
              <div className={'moreCaseText'}>{item.type}</div>
              <div className={'moreCaseTitle'}>Age range</div>
              <div className={'moreCaseText'}>
                {item.age.from} - {item.age.to}
              </div>
              <div className={'moreCaseTitle'}>Start date</div>
              <div className={'moreCaseText'}>
                {moment(item.startDate).format('MM/DD/YYYY')}
              </div>
            </div>
            <div className={'moreCaseCategoryBlock'}>
              <div className={'moreCaseTitle'}>Category</div>
              <div className={'moreCaseText'}>
                {item.tags.map((i) => i).join(', ')}
              </div>
              <div className={'moreCaseTitle'}>Max impressions</div>
              <div className={'moreCaseText'}>{item.maxImpressions}</div>
              {!item?.isEntire ? (
                <>
                  <div className={'moreCaseTitle'}>Choose a location</div>
                  <div className={'moreCaseText'}>{item.city}</div>
                </>
              ) : (
                <>
                  <div className={'moreCaseTitle'}>Choose a location</div>
                  <div className={'moreCaseText'}>All cities</div>
                </>
              )}
              <div className={'moreCaseTitle'}>End date</div>
              <div className={'moreCaseText'}>
                {moment(item.endDate).format('MM/DD/YYYY')}
              </div>
              <div className={'moreCaseTitle'}>Clicks</div>
              <div className={'moreCaseText'}>{item.clicks}</div>
            </div>
          </div>
        ) : null}
      </div>
      <DeleteModal
        show={openDeleteModal}
        close={() => setOpenDeleteModal(false)}
        title='Delete advertising'
        description='Delete this advertising?'
        onClick={deleteAds}
      />
    </>
  )
}

export default Cart
