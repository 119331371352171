import { SHOW_ALERT } from "../actions/actionTypes";

const initialState = {
  message: "",
  show: false,
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return { ...state, message: action.payload, show: !state.show };

    default:
      return state;
  }
};

export default alertReducer;
