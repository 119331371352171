import apiClient from './apiClient'

class AuthApi {
  static auth(data) {
    return apiClient
      .post(`/admins/auth`, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => data.data)
  }
}

export default AuthApi
