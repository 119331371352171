import apiClient from './apiClient'
import { getToken } from '../utils/localStorage'

class ReportsAPI {
  static token = getToken(); // Make token static

  static getReports(status, page, sort, limit = 20) {
    return apiClient
      .get(
        `/reports/?status=${status}&page=${page}&limit=${limit}&sort=${sort}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
          },
        }
      )
      .then(({ data }) => data.data)
  }

  static getReportsBadge() {
    return apiClient
      .get('/reports/badge', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(({ data }) => data.data)
  }

  static readReports() {
    return apiClient
      .put(
        '/reports/view',
        {},
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
          },
        }
      )
      .then(({ data }) => data.data)
  }

  static deleteReport(id) {
    return apiClient
      .delete(`/reports/${id}/`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(({ data }) => data.data)
  }

  static archiveReport(id) {
    return apiClient
      .put(
        `/reports/${id}/`,
        { status: 'completed' },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer  ${this.token}`,
          },
        }
      )
      .then(({ data }) => data.data)
  }
  static inProgressReport(id) {
    return apiClient
      .put(
        `/reports/${id}/`,
        { status: 'inProgress' },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
          },
        }
      )
      .then(({ data }) => data.data)
  }
}

export default ReportsAPI
