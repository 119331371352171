//-----------Basic imports----------------------------
import React, { useRef, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

import styles from './Statistics.module.scss'

//-----------Libraries--------------------------------

import { Button, MultiSelectInput, SelectInput } from '../../../components'
import { page, tagsOptions, type, genderOptions } from '../../../consts/ads'
import InputMask from 'react-input-mask'
import { Formik } from 'formik'
import moment from 'moment'
import createAdvSchema from '../../../validation/schemas/createAdvSchema'
import Autocomplete from 'react-google-autocomplete'
import ICONS from '../../../assets/icons'
import { AdsAPI } from '../../../APIs'
import { useHistory, useLocation } from 'react-router-dom'
import ROUTES from '../../../route/routes'
import { useEffect } from 'react'

const CreateNew = () => {
  const location = useLocation()

  const [startDate, setStartDate] = useState(
    location?.state?.item?.startDate
      ? new Date(location?.state?.item?.startDate)
      : new Date()
  )
  const [showStartDate, setShowStartDate] = useState(false)
  const [endDate, setEndDate] = useState(
    location?.state?.item?.endDate
      ? new Date(location?.state?.item?.endDate)
      : // : new Date(Date.now() + 3600 * 1000 * 24)
        new Date(startDate.setDate(startDate.getDate()) + 3600 * 1000 * 24)
  )

  useEffect(() => {
    setEndDate(
      new Date(startDate.setDate(startDate.getDate()) + 3600 * 1000 * 24)
    )
  }, [startDate])

  const [showEndDate, setShowEndDate] = useState(false)
  const [mainError, setMainError] = useState('')

  const [selectPage, setSelectPage] = useState(() =>
    location?.state?.item?.page
      ? page.find((i) => i.label === location?.state?.item?.page)
      : ''
  )
  const [category, setCategory] = useState(
    location?.state?.item?.tags ? location?.state?.item?.tags : []
  )
  const [selectType, setSelectType] = useState(() =>
    location?.state?.item?.type
      ? type.find((i) => i.label === location?.state?.item?.type)
      : ''
  )
  const [genderType, setGenderType] = useState(
    location?.state?.item?.gender ? location?.state?.item?.gender : []
    // ? genderOptions.find((i) => i.label === location?.state?.item?.gender)
    // : []
  )
  const [imageState, setImageState] = useState(
    location?.state?.item?.image ? location?.state?.item?.image : null
  )
  const [imageData, setImageData] = useState(
    location?.state?.item?.image ? location?.state?.item?.image : null
  )
  const [sendNowTime, setSendNowTime] = useState(
    location?.state?.item?.isSendNow ? true : false
  )
  const [addButton, setAddButton] = useState(
    location?.state?.item?.isHaveButton ? true : false
  )
  const [lat, setLat] = useState(
    location?.state?.item?.location?.coordinates?.length
      ? location?.state?.item?.location?.coordinates[1]
      : 0
  )
  const [lng, setLng] = useState(
    location?.state?.item?.location?.coordinates?.length
      ? location?.state?.item?.location?.coordinates[0]
      : 0
  )
  const [isEntire, setIsEntire] = useState(
    location?.state?.item?.isEntire ? location?.state?.item?.isEntire : false
  )

  const history = useHistory()

  const hiddenFileInput = useRef(null)

  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  const uploadFile = (file) => {
    const formData = new FormData()
    formData.append('file', file)
    return AdsAPI.savePhoto(formData)
  }

  const handleChangeFile = async (event) => {
    const res = await uploadFile(event.target.files[0])
    setImageData(res.url)
    var fReader = new FileReader()

    fReader.readAsDataURL(event.target.files[0])
    fReader.onloadend = function (event) {
      setImageState(event.target.result)
    }
  }

  const hundleAge = (value) => {
    const from = value.slice(0, 2)
    const to = value.slice(5, 7)
    console.log(from)
    return {
      from: +from,
      to: +to,
    }
  }

  console.log('location?.state?.item', location?.state?.item)
  return (
    <Formik
      initialValues={{
        time: location?.state?.item?.time ? location?.state?.item?.time : '',
        age: location?.state?.item?.age
          ? `${location?.state?.item?.age?.from} - ${location?.state?.item?.age?.to}`
          : '',
        maxImpressions: location?.state?.item?.maxImpressions
          ? location?.state?.item?.maxImpressions
          : '',
        link: location?.state?.item?.link ? location?.state?.item?.link : '',
        city: location?.state?.item?.city ? location?.state?.item?.city : '',
        location: location?.state?.item?.location
          ? location?.state?.item?.location
          : '',
        title: location?.state?.item?.title ? location?.state?.item?.title : '',
        description: location?.state?.item?.description
          ? location?.state?.item?.description
          : '',
        btnText: location?.state?.item?.buttonText
          ? location?.state?.item?.buttonText
          : '',
      }}
      validationSchema={createAdvSchema}
      validateOnBlur={false}
      validateOnChange={false}
      initialErrors={{}}
      onSubmit={async (value) => {
        if (location?.state?.item) {
          const data = {
            title: value.title,
            description: value.description,
            image: imageData,
            isHaveButton: addButton,
            buttonText: value.btnText,
            page: selectPage.label,
            type: selectType.label,
            tags: category,
            startDate: moment(startDate).format('YYYY-MM-DD'),

            endDate: moment(endDate).format('YYYY-MM-DD'),

            age: hundleAge(value.age),
            gender: genderType,
            time: value.time,
            isEntire: isEntire,
            maxImpressions: value.maxImpressions,
            alreadyImpressions: value.maxImpressions,
            isSendNow: sendNowTime,
            link: value.link,
            city: value.city,
            location: {
              type: 'Point',
              coordinates: [lng, lat],
            },
          }
          try {
            await AdsAPI.editAds(location?.state?.item?.id, data)
            history.push(ROUTES.ads)
          } catch (e) {
            setMainError(e.response.data.message)
            console.log('e', e)
          }
        } else {
          const data = {
            title: value.title,
            description: value.description,
            image: imageData,
            isHaveButton: addButton,
            buttonText: value.btnText,
            page: selectPage.label,
            type: selectType.label,
            tags: category,
            startDate: moment(startDate).format('YYYY-MM-DD'),

            endDate: moment(endDate).format('YYYY-MM-DD'),

            age: hundleAge(value.age),
            gender: genderType,
            time: value.time,
            isEntire: isEntire,
            maxImpressions: value.maxImpressions,
            alreadyImpressions: value.maxImpressions,
            isSendNow: sendNowTime,
            link: value.link,
            city: value.city,
            location: {
              type: 'Point',
              coordinates: [lng, lat],
            },
          }
          try {
            await AdsAPI.saveAds(data)
            history.push(ROUTES.ads)
          } catch (e) {
            setMainError(e.response.data.message)
            console.log('e', e)
          }
        }
      }}
    >
      {({ handleChange, values, errors, handleSubmit }) => (
        <>
          <div className={styles.statistics}>
            <div className={styles.statistics__total}>
              <div className={styles.colInputs}>
                <div className={styles.title}>Select a page</div>
                <div className={styles.selectCase}>
                  <SelectInput
                    options={page}
                    placeholder='Home'
                    span={selectPage.title}
                    onChange={setSelectPage}
                  />
                </div>

                <div className={styles.title}>Post type</div>
                <div className={styles.selectCase}>
                  <SelectInput
                    options={type}
                    placeholder='Full screen Ad'
                    span={selectType.title}
                    onChange={setSelectType}
                  />
                </div>

                <div className={styles.title}>Age range</div>
                <InputMask
                  className={
                    errors.age
                      ? styles.errorInput
                      : values.age
                      ? styles.activeInput
                      : styles.input
                  }
                  placeholder='21 - 35'
                  mask='99 - 99'
                  maskChar=''
                  value={values.age}
                  onChange={(text) => {
                    // const regex = '(1[012]|[1-9]):[0-5][0-9](\\s)?(?i)(am|pm)'
                    handleChange('age')(text.target.value)
                    delete errors.age
                  }}
                />
                {errors.age && <p className={styles.errorText}>{errors.age}</p>}

                <div className={styles.title}>Gender</div>
                <div className={styles.selectCase}>
                  {/* <SelectInput
                    options={genderOptions}
                    placeholder='Man'
                    span={genderType.title}
                    onChange={setGenderType}
                  /> */}
                  <MultiSelectInput
                    options={genderOptions}
                    placeholder='Man'
                    span={genderType}
                    onChange={setGenderType}
                  />
                </div>
                <div className={styles.title}>Max impressions</div>
                <InputMask
                  className={
                    errors.maxImpressions
                      ? styles.errorInput
                      : values.maxImpressions
                      ? styles.activeInput
                      : styles.input
                  }
                  mask='999999'
                  maskChar=''
                  placeholder='100000'
                  value={values.maxImpressions}
                  onChange={(text) => {
                    handleChange('maxImpressions')(text.target.value)
                    delete errors.maxImpressions
                  }}
                />
                {errors.maxImpressions && (
                  <p className={styles.errorText}>{errors.maxImpressions}</p>
                )}

                <div className={styles.title}>Link</div>
                <input
                  className={
                    errors.link
                      ? styles.errorInput
                      : values.link
                      ? styles.activeInput
                      : styles.input
                  }
                  placeholder='Link'
                  value={values.link}
                  onChange={(text) => {
                    handleChange('link')(text.target.value)
                    delete errors.link
                  }}
                ></input>
                {errors.link && (
                  <p className={styles.errorText}>{errors.link}</p>
                )}
              </div>
              <div className={styles.colInputs}>
                <div className={styles.title}>Show to: category</div>
                <div className={styles.selectCase}>
                  <MultiSelectInput
                    options={tagsOptions}
                    placeholder='Bear'
                    span={category}
                    onChange={setCategory}
                  />
                </div>

                <div className={styles.title}>Start date</div>
                <div
                  className={styles.iconContainer}
                  onClick={() => setShowStartDate((prev) => !prev)}
                >
                  <div className={styles.inputDate}>
                    <p>{moment(startDate).format('MM/DD/YYYY')}</p>
                  </div>
                  <div className={styles.icon}>{ICONS.calendar}</div>
                </div>
                <div className={styles.calendarContainer}>
                  <Calendar
                    onChange={setStartDate}
                    className={
                      showStartDate
                        ? styles.calendarStartShow
                        : styles.calendarStartHide
                    }
                    minDate={new Date()}
                    value={startDate}
                  />
                </div>

                <div className={styles.title}>End date </div>
                <div
                  className={styles.iconContainer}
                  onClick={() => setShowEndDate((prev) => !prev)}
                >
                  <div className={styles.inputDate}>
                    <p>{moment(endDate).format('MM/DD/YYYY')}</p>
                  </div>
                  <div className={styles.icon}>{ICONS.calendar}</div>
                </div>
                <div className={styles.calendarContainer}>
                  <Calendar
                    onChange={setEndDate}
                    className={
                      showEndDate
                        ? styles.calendarEndShow
                        : styles.calendarEndHide
                    }
                    // showWeekNumbers
                    minDate={
                      new Date(
                        startDate.setDate(startDate.getDate()) +
                          3600 * 1000 * 24
                      )
                    }
                    value={endDate}
                  />
                </div>

                <div className={styles.title}>Time</div>
                <div className={styles.iconContainer}>
                  <InputMask
                    className={
                      errors.time
                        ? styles.errorInput
                        : values.time
                        ? styles.activeInput
                        : styles.input
                    }
                    placeholder='09:54 pm'
                    mask='99:99 aa'
                    maskChar=''
                    value={values.time.toLowerCase()}
                    onChange={(text) => {
                      handleChange('time')(text.target.value)
                      delete errors.time
                    }}
                  />
                  <div className={styles.icon}>{ICONS.time}</div>
                </div>
                {errors.time && (
                  <p className={styles.errorText}>{errors.time}</p>
                )}

                {!sendNowTime ? (
                  <div
                    className={styles.btnAppleContainer}
                    onClick={() => {
                      const newTime = Date.now()
                      const handleTime = moment(newTime).format('hh:mm A')
                      console.log('handleTime', handleTime)
                      handleChange('time')(handleTime)
                      delete errors.time

                      setSendNowTime(true)
                    }}
                  >
                    <div className={styles.btnAppleDis}>
                      <div className={styles.btnAppleActive} />
                    </div>
                    <p>Send now</p>
                  </div>
                ) : (
                  <div
                    className={styles.btnAppleContainer}
                    onClick={() => {
                      setSendNowTime(false)
                      handleChange('time')('')
                    }}
                  >
                    <div className={styles.btnApple}>
                      <div className={styles.btnAppleActive} />
                    </div>
                    <p>Send now</p>
                  </div>
                )}
                {!isEntire ? (
                  <div
                    className={styles.btnAppleEntire}
                    onClick={() => {
                      setIsEntire(true)
                      handleChange('city')('London, UK')
                    }}
                  >
                    <div className={styles.btnAppleDis}>
                      <div className={styles.btnAppleActive} />
                    </div>
                    <p>All cities</p>
                  </div>
                ) : (
                  <div
                    className={styles.btnAppleEntire}
                    onClick={() => {
                      setIsEntire(false)
                      handleChange('city')('')
                    }}
                  >
                    <div className={styles.btnApple}>
                      <div className={styles.btnAppleActive} />
                    </div>
                    <p>All cities</p>
                  </div>
                )}
                {!isEntire ? (
                  <>
                    <div className={styles.title}>Choose a location</div>

                    <Autocomplete
                      language='en'
                      className={
                        errors.city
                          ? styles.errorInput
                          : values.city
                          ? styles.activeInput
                          : styles.input
                      }
                      placeholder={
                        location?.state?.item?.city
                          ? location?.state?.item?.city
                          : 'Los Angeles, 20 miles'
                      }
                      apiKey='AIzaSyBVQb3TR91EuqYxm5X8rkib2wXZC7FG4VE'
                      onChange={() => {
                        delete errors.city
                      }}
                      onPlaceSelected={(place) => {
                        const a = place.geometry.location.lat
                        const b = place.geometry.location.lng
                        setLat(a())
                        setLng(b())
                        handleChange('city')(place.formatted_address)
                        delete errors.city
                      }}
                    />
                    {errors.city && (
                      <p className={styles.errorText}>{errors.city}</p>
                    )}
                  </>
                ) : null}
              </div>
            </div>

            <div className={styles.statistics__total2}>
              {!imageState && (
                <a className={styles.bgCase} href='#!' onClick={handleClick}>
                  Click to upload cover image
                </a>
              )}
              <input
                type='file'
                ref={hiddenFileInput}
                className={styles.inputFile}
                placeholder='Link'
                onChange={handleChangeFile}
                style={{ display: 'none' }}
              ></input>

              {imageState && (
                <a className={styles.bgCase2} href='#!' onClick={handleClick}>
                  <img
                    src={imageState}
                    alt='img'
                    className={styles.bgCaseImg}
                  />
                </a>
              )}
              <div className={styles.title}>Title</div>
              <input
                className={
                  errors.title
                    ? styles.errorInput
                    : values.title
                    ? styles.activeInput
                    : styles.input
                }
                placeholder='Before Bed Routines'
                value={values.title}
                onChange={(text) => {
                  handleChange('title')(text.target.value)
                  delete errors.title
                }}
              ></input>
              {errors.title && (
                <p className={styles.errorText}>{errors.title}</p>
              )}
              <div className={styles.title}>Ad Description</div>
              <textarea
                className={
                  errors.description
                    ? styles.errorTextarea
                    : values.description
                    ? styles.activeTextarea
                    : styles.textarea
                }
                placeholder='Sleep meditation for you to relax '
                value={values.description}
                onChange={(text) => {
                  handleChange('description')(text.target.value)
                  delete errors.description
                }}
              ></textarea>
              {errors.description && (
                <p className={styles.errorText}>{errors.description}</p>
              )}

              {selectType.label === 'fullScreen' ? (
                !addButton ? (
                  <div
                    className={styles.addBtnContainer}
                    onClick={() => {
                      setAddButton(true)
                    }}
                  >
                    <div className={styles.btnAppleDis}>
                      <div className={styles.btnAppleActive} />
                    </div>
                    <p>Add a button</p>
                  </div>
                ) : (
                  <div
                    className={styles.addBtnContainer}
                    onClick={() => {
                      setAddButton(false)
                    }}
                  >
                    <div className={styles.btnApple}>
                      <div className={styles.btnAppleActive} />
                    </div>
                    <p>Add a button</p>
                  </div>
                )
              ) : null}
              {addButton ? (
                <>
                  <div className={styles.title}>Button text</div>
                  <input
                    className={
                      errors.btnText
                        ? styles.errorInput
                        : values.btnText
                        ? styles.activeInput
                        : styles.input
                    }
                    placeholder='Click me'
                    value={values.btnText}
                    onChange={(text) => {
                      handleChange('btnText')(text.target.value)
                      delete errors.btnText
                    }}
                  ></input>
                  {errors.btnText && (
                    <p className={styles.errorText}>{errors.btnText}</p>
                  )}
                </>
              ) : null}
              <div className={styles.btnCase}>
                <p className={styles.mainError}>{mainError}</p>
                <Button
                  type='submit'
                  onClick={handleSubmit}
                  disabled={
                    !selectPage || !category || !selectType || !genderType
                  }
                >
                  Publish
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  )
}

export default CreateNew
