import {
  GET_CATEGORIES,
  CREATE_CATEGORIES,
  DELETE_CATEGORIES,
  EDIT_CATEGORIES,
  DELETE_SUBCATEGORIES,
  EDIT_SUBCATEGORIES,
  CREATE_SUBCATEGORIES,
} from "./actionTypes";

export const getCategoriesAction = (payload) => ({
  type: GET_CATEGORIES,
  payload,
});

export const createCategoriesAction = (payload) => ({
  type: CREATE_CATEGORIES,
  payload,
});

export const editCategoriesAction = (payload) => ({
  type: EDIT_CATEGORIES,
  payload,
});

export const deleteCategoriesAction = (payload) => ({
  type: DELETE_CATEGORIES,
  payload,
});

export const deleteSubCategoriesAction = (payload) => ({
  type: DELETE_SUBCATEGORIES,
  payload,
});

export const editSubCategoriesAction = (payload) => ({
  type: EDIT_SUBCATEGORIES,
  payload,
});

export const createSubCategoriesAction = (payload) => ({
  type: CREATE_SUBCATEGORIES,
  payload,
});
