import * as yup from 'yup'

const timeStamp = new RegExp(
  /(^0?[1-9]|1[0-2]):([0-5]\d)\s?(([Aa]|[Pp])\.?[Mm]\.?)/
)

// const dateStamp = new RegExp(
//   /(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/
// )

const createAdvSchema = yup.object().shape({
  // dataStart: yup
  //   .string()
  //   .matches(dateStamp, 'format must be 12/09/2022 pm')
  //   .min(10, 'fill in the field correctly')
  //   .required(),
  // dataEnd: yup.string().min(10, 'fill in the field correctly').required(),
  // time: yup.string().min(8, 'fill in the field correctly').required(),
  time: yup
    .string()
    .min(8, 'fill in the field correctly')
    .matches(timeStamp, 'format must be 12:59 pm')
    .required(),
  age: yup.string().min(7, 'fill in the field correctly').required(),
  maxImpressions: yup.string().required(),
  link: yup.string().required(),
  city: yup.string().required(),
  title: yup.string().required(),
  description: yup.string().required(),
})

export default createAdvSchema
