//-----------------Helpers---------------------------------------
import { responseHandler } from "../helpers";

//-----------------Utils---------------------------------------
import fetchRequest from "../utils/fetchRequest";

//-----------------Redux action creators---------------------------------------
import {
  getCategoriesAction,
  createCategoriesAction,
  deleteCategoriesAction,
  editCategoriesAction,
  editSubCategoriesAction,
  deleteSubCategoriesAction,
  createSubCategoriesAction,
} from "../redux/actions/categoriesActions";
import { showAlertAction } from "../redux/actions/alertActions";

//-----------------Libraries---------------------------------------
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

//-------------AWS configuration-----------------------------------------

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SERVER_SECRET_ACCESS_KEY,
  params: {
    Bucket: "instapro-bucket",
  },
});

//-------------------Get categories--------------------------------------

export const getCategories =
  (all, setCategories, setLoading) => async (dispatch, getState) => {
    if (!all) {
      dispatch(getCategoriesAction({ loading: true }));

      const { categoriesPage } = getState().categoriesReducer;

      const response = await fetchRequest(
        all ? `api/category/list` : `api/category/list?page=${categoriesPage}`,
        "GET"
      );

      const error = responseHandler(response);

      dispatch(getCategoriesAction({ loading: false }));

      if (!error) {
        dispatch(
          getCategoriesAction({ categories: response.categoryClusters })
        );
      }
    } else {
      setLoading(true);
      const response = await fetchRequest(`api/category/list?page=1`, "GET");

      const error = responseHandler(response);

      setLoading(false);

      if (!error) {
        setCategories(response.categoryClusters);
      }
    }
  };

//-------------------Create category--------------------------------------

export const createCategory = (body) => (dispatch) => {
  dispatch(createCategoriesAction({ loading: true, success: false }));

  const params = {
    Body: body.photo,
    Key: `photo/categories/${uuidv4()}`,
  };

  s3.upload(params, async function (err, fileData) {
    if (err) {
      console.warn("AWS video uploaded error---->", err);
      dispatch(showAlertAction("Error when uploading photo to aws"));
    } else {
      const response = await fetchRequest("api/category/cluster", "POST", {
        ...body,
        photo: fileData.Location,
      });

      const error = responseHandler(response, "creating category");

      dispatch(createCategoriesAction({ loading: false }));

      if (!error) {
        dispatch(
          createCategoriesAction({
            loading: false,
            success: true,
          })
        );
        dispatch(showAlertAction("Succesfuly created a new category!"));
      } else {
        dispatch(showAlertAction(error));
      }
    }
  });
};

//-------------------Edit category--------------------------------------

export const editCategory = (body, category) => async (dispatch) => {
  dispatch(editCategoriesAction({ loading: true, success: false }));

  const params = {
    Body: body.photo,
    Key: category.photo.slice(category.photo.indexOf("photo/categories")),
  };

  console.log(params);

  if (typeof body.photo !== "string") {
    s3.putObject(params, async function (err, fileData) {
      if (err) {
        console.warn("AWS video uploaded error---->", err);
        dispatch(showAlertAction("Error when uploading photo to aws"));
      } else {
        const response = await fetchRequest(
          `api/category/cluster?id=${category._id}`,
          "PUT",
          { ...body, photo: fileData.Location }
        );

        const error = responseHandler(response, "edditing category");

        dispatch(editCategoriesAction({ loading: false }));

        if (!error) {
          dispatch(
            editCategoriesAction({
              newCategory: response.newCategoryCluster,
              loading: false,
            })
          );
          dispatch(showAlertAction("Succesfuly edited a  category!"));
        } else {
          dispatch(showAlertAction(error));
        }
      }
    });
  }
};

//-------------------Delete category--------------------------------------

export const deleteCategory =
  (data, setLoading, onSucess) => async (dispatch) => {
    setLoading(true);

    const params = {
      Delete: {
        Objects: [
          { Key: data.photo.slice(data.photo.indexOf("photo/categories")) },
        ],
      },
    };

    console.log("params---->", params);

    s3.deleteObjects(params, async function (err, fileData) {
      if (err) {
        console.warn("deleting video error======>", err);
      } else {
        const response = await fetchRequest(
          `api/category/cluster?id=${data._id}`,
          "DELETE"
        );

        const error = responseHandler(response, "deleting category");

        setLoading(false);

        if (!error) {
          dispatch(
            deleteCategoriesAction({
              _id: data._id,
            })
          );
          onSucess();
          dispatch(showAlertAction("Succesfuly deleted a category!"));
        } else {
          dispatch(showAlertAction(error));
        }
      }
    });
  };

//-------------------Create subCategory--------------------------------------

export const createSubCategory =
  (_id, setLoading, body, onSucess) => async (dispatch) => {
    setLoading(true);

    console.log(body);

    const response = await fetchRequest(
      `api/category/category/?id=${_id}`,
      "POST",
      body
    );

    const error = responseHandler(response, "creating sub category");

    setLoading(false);

    if (!error) {
      dispatch(
        createSubCategoriesAction({ category: response.categoryCluster })
      );
      onSucess(response.categoryCluster.categories.slice(-1));
      dispatch(showAlertAction("Succesfuly created a new sub category!"));
    } else {
      dispatch(showAlertAction(error));
    }
  };

//-------------------Delete subCategory--------------------------------------

export const deleteSubCategory =
  (_id, setLoading, onSucess) => async (dispatch) => {
    setLoading(true);

    const response = await fetchRequest(
      `api/category/category?id=${_id}`,
      "DELETE"
    );

    const error = responseHandler(response, "deleting sub category");

    setLoading(false);

    if (!error) {
      dispatch(
        deleteSubCategoriesAction({
          _id: _id,
        })
      );
      onSucess();
      dispatch(showAlertAction("Succesfuly deleted a sub category!"));
    } else {
      dispatch(showAlertAction(error));
    }
  };

//-------------------Delete subCategory--------------------------------------

export const editSubCategory = (_id, setLoading, name) => async (dispatch) => {
  setLoading(true);

  console.log({
    name: name,
    _id: _id,
  });

  const response = await fetchRequest(
    `api/category/category?id=${_id}`,
    "PUT",
    {
      name: name,
    }
  );

  const error = responseHandler(response, "editing sub category");

  setLoading(false);

  if (!error) {
    dispatch(
      editSubCategoriesAction({
        _id: _id,
        name: name,
      })
    );
    dispatch(showAlertAction("Succesfuly edited a sub category!"));
  } else {
    dispatch(showAlertAction(error));
  }
};
