import { combineReducers } from 'redux'
import authReducer from './authReducer'
import alertReducer from './alertReducer'
import adminsReducer from './adminsReducer'
import categoriesReducer from './categoriesReducer'
import expertsReducer from './expertsReducer'
import usersReducer from './usersReducer'
import reportsReducer from './reportsReducer'

const rootReducer = combineReducers({
  authReducer,
  adminsReducer,
  alertReducer,
  categoriesReducer,
  expertsReducer,
  usersReducer,
  reportsReducer,
})

export default rootReducer
