//---------------------Basic imports-----------------------
import React from 'react'
import './FiltersModal.scss'

//---------------------Components-----------------------
import { Button, Loader, Modal } from '..'

//---------------------Libraries------------------------
import { useDispatch } from 'react-redux'

//---------------------Assets-----------------------
import ICONS from '../../assets/icons'

//---------------APIs---------------------------------
import { getCategories } from '../../APIs/categories'

const FiltersModal = ({ show, close }) => {
  //---------------------Handlers-----------------------

  const [state, setState] = React.useState({
    loading: false,
    categories: [],
  })

  const dispatch = useDispatch()

  //---------------------State-----------------------

  const { loading, categories } = state

  //---------------------Handlers-----------------------

  const setLoading = (state) =>
    setState((prev) => ({ ...prev, loading: state }))

  const setCategories = (data) =>
    setState((prev) => ({ ...prev, categories: data }))

  console.log(state)

  React.useEffect(() => {
    dispatch(getCategories(true, setCategories, setLoading))
  }, [dispatch])

  //---------------------Layout-----------------------

  return (
    <Modal
      closeBtn
      className='filtersModal'
      visible={show}
      onClosePopup={close}
    >
      <h2>Filters</h2>

      <div className='filtersModal__content'>
        <ul>
          <h3>Category</h3>
          {loading ? (
            <Loader />
          ) : (
            categories.map((item, index) => (
              <li key={index}>
                <label htmlFor={item._id.toString()}>
                  <input
                    type='checkbox'
                    name={item._id.toString()}
                    id={item._id.toString()}
                  />
                  <div>{ICONS.utils.check}</div>
                  {item.name}
                </label>
              </li>
            ))
          )}
        </ul>
        <ul>
          <h3>Pro</h3>

          <li>
            <label htmlFor='Verified'>
              <input type='checkbox' name='Verified' id='Verified' />
              <div>{ICONS.utils.check}</div>
              Verified
            </label>
          </li>
          <li>
            <label htmlFor='Not Verified'>
              <input type='checkbox' name='Not Verified' id='Not Verified' />
              <div>{ICONS.utils.check}</div>
              Not Verified
            </label>
          </li>
        </ul>
      </div>

      <div className='filtersModal__footer'>
        <button>Clean all</button>
        <Button>
          <span>Save filters</span>
        </Button>
      </div>
    </Modal>
  )
}

export default FiltersModal
