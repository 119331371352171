//-----------Basic imports----------------------------
import React from 'react'
import styles from './Statistics.module.scss'
import Graph from '../../components/Graph'

const Statistics = () => {
  return (
    <div className={styles.categories}>
      {/* <div className={styles.users__header}>
        <div className={styles.bigTitle}>Statistics</div>
      </div> */}
      <div className={styles.topStatistic}>
        <div className={styles.topStatisticLeft}>
          <div className={styles.graphTitleBig}>Registered Users</div>
          <div className={styles.bigNumber}>2000</div>
          <div className={styles.graphTitleSmall}>Active users today</div>

          <div className={styles.numberToday}>347</div>
        </div>
        <div className={styles.topStatisticGraph}>
          <Graph />
        </div>
      </div>
      <div className={styles.bottomStatistic}>
        <div className={styles.bigCase}>
          <div className={styles.graphTitleBig}>Total Revenue</div>
          <div className={styles.bigNumberBottom}>$29,347.45</div>
          <div className={styles.graphTitleSmall}>Today</div>
          <div className={styles.numberToday}>$1,347.45</div>
        </div>
        <div className={styles.bigCase}>
          <div className={styles.graphTitleBig}>Total app installs</div>
          <div className={styles.bigNumberBottom}>70,347</div>
          <div className={styles.graphTitleSmall}>Today</div>
          <div className={styles.numberToday}>32,347</div>
        </div>
        <div className={styles.bigCase}>
          <div className={styles.graphTitleBig}>Total transactions</div>
          <div className={styles.bigNumberBottom}>9,347</div>
          <div className={styles.graphTitleSmall}>Today</div>
          <div className={styles.numberToday}>47</div>
        </div>
        <div className={[styles.bigCase, { borderRight: 'none' }]}>
          <div className={styles.graphTitleBig}>Total Pro accounts</div>
          <div className={styles.bigNumberBottom}>2,347</div>
          <div className={styles.graphTitleSmall}>Today</div>
          <div className={styles.numberToday}>347</div>
        </div>
      </div>
    </div>
  )
}

export default Statistics
