const Delete = () => (
  <svg
    width='18'
    height='16'
    viewBox='0 0 18 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='1.5'
      y='1.5'
      width='15'
      height='3'
      stroke='#11142D'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.5 6.5V14.5H3.5V6.5'
      stroke='#11142D'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <rect
      x='6.5'
      y='7.5'
      width='5'
      height='3'
      stroke='#11142D'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Delete
