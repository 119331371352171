//---------------------Basic imports-----------------------
import React from 'react'
import './SelectInput.scss'

//---------------------Libraries-----------------------
import clsx from 'clsx'

//---------------------Assets--------------------------
import ICONS from '../../assets/icons'

const MultiSelectInput = ({
  options = [],
  selected,
  span = [],
  onChange,
  onChangeHandle,
  placeholder = 'Choose',
}) => {
  //------------------Hooks---------------------------

  const [showMore, setShowMore] = React.useState(false)

  //------------------Handlers---------------------------

  const btnHandler = () => setShowMore((prev) => !prev)

  const select = (name) => {
    if (onChangeHandle) {
      onChangeHandle(name)
      return
    }

    onChange(span?.filter((с) => с !== name))
    if (span?.findIndex((c) => c === name) === -1) {
      onChange((prev) => [...prev, name])
    }
  }

  //---------------------Layout-----------------------
  return (
    <div
      className={clsx('multiSelectInput', showMore && 'multiSelectInput-more')}
    >
      <button
        onClick={btnHandler}
        type='button'
        className='multiSelectInput__btn'
      >
        <span className={span.length > 0 ? 'customSpan' : 'customPlaceholder'}>
          {span.length > 0 ? span.join(', ') : placeholder}
        </span>
        {ICONS.utils.smallArrow}
      </button>
      <div className='multiSelectInput__dropdown'>
        <ul>
          {options.map((item, index) => (
            <>
              <li key={index}>
                <button
                  className={
                    span.find((i) => i === item.title) && 'selectedActive'
                  }
                  onClick={() => select(item.title)}
                >
                  {item.title}
                </button>
              </li>
            </>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default MultiSelectInput
