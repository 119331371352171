const Back = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.4938 11.0004L11.3474 5.42921L9.96861 3.98047L2.31059 11.2689C1.89679 11.6628 1.89642 12.3226 2.30978 12.7169L9.96781 20.0216L11.3482 18.5744L5.50459 13.0004L21.9999 13.0014L22.0001 11.0014L13.7469 11.0009L5.4938 11.0004Z'
      fill='#808185'
    />
  </svg>
)

export default Back
