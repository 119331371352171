import {
  GET_EXPERTS,
  APPROVE_EXPERT,
  DELETE_EXPERT,
  REMOVE_PRO_STATUS,
  SERCH_EXPERTS,
} from "./actionTypes";

export const getExpertsAction = (payload) => ({
  type: GET_EXPERTS,
  payload,
});

export const searchExpertAction = (payload) => ({
  type: SERCH_EXPERTS,
  payload,
});

export const approveExpertAction = (payload) => ({
  type: APPROVE_EXPERT,
  payload,
});

export const deleteExpertAction = (payload) => ({
  type: DELETE_EXPERT,
  payload,
});

export const removePROStatusAction = (payload) => ({
  type: REMOVE_PRO_STATUS,
  payload,
});
