const Time = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.3996 7.99961C12.3996 7.50255 11.9967 7.09961 11.4996 7.09961C11.0026 7.09961 10.5996 7.50255 10.5996 7.99961V12.4996C10.5996 12.8748 10.8323 13.2106 11.1836 13.3423L15.1836 14.8423C15.649 15.0168 16.1678 14.781 16.3423 14.3156C16.5168 13.8502 16.281 13.3314 15.8156 13.1569L12.3996 11.8759V7.99961Z'
      fill='#131313'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9996 2.09961C6.53199 2.09961 2.09961 6.53199 2.09961 11.9996C2.09961 17.4672 6.53199 21.8996 11.9996 21.8996C17.4672 21.8996 21.8996 17.4672 21.8996 11.9996C21.8996 6.53199 17.4672 2.09961 11.9996 2.09961ZM3.89961 11.9996C3.89961 7.5261 7.5261 3.89961 11.9996 3.89961C16.4731 3.89961 20.0996 7.5261 20.0996 11.9996C20.0996 16.4731 16.4731 20.0996 11.9996 20.0996C7.5261 20.0996 3.89961 16.4731 3.89961 11.9996Z'
      fill='#131313'
    />
  </svg>
)

export default Time
