//---------------------Basic imports-----------------------
import React from 'react'
import './ReviewsModal.scss'

import { Button, Modal } from '..'

import ICONS from '../../assets/icons'
import moment from 'moment'
const ReviewsModal = ({ show, close, data }) => {
  const infoTabs = ['Reviewer', 'Review', 'Rated']

  return (
    <Modal className='reviewsModal' visible={show} onClosePopup={close}>
      <div className='reviewsModal__header'>
        <div className='reviewsModal__userBlock'>
          <div></div>
          <div>
            <p>{data?.fullName}</p>
            <p>
              {data?.categories?.map((item) => (
                <span style={{ marginRight: 5 }}>{item?.category?.name} </span>
              ))}
            </p>
          </div>
        </div>

        <h2>Reviews/votes</h2>
      </div>

      <div className='reviewsModal__infoBar'>
        {infoTabs.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </div>

      <ul>
        {data?.ratings.map((item, index) => (
          <li key={index} className={'eviewsModal__item'}>
            <div className='reviewsModal__itemBlock'>
              {item?.expert?.fullName} {item?.user?.fullName}
            </div>
            <div className='reviewsModal__itemBlock'>
              <div>
                <span>
                  {moment(new Date(item.createdAt)).format('DD/MM/YY')}
                </span>
              </div>
              <p>{item.description}</p>
            </div>
            <div className='reviewsModal__itemBlock'>
              {new Array(item.rating)
                .fill(0)
                .map((item, index) => ICONS.utils.star)}
            </div>
          </li>
        ))}
      </ul>

      <Button onClick={close}>
        <span>Close</span>
      </Button>
    </Modal>
  )
}

export default ReviewsModal
