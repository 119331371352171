//---------------------Basic imports-----------------------
import React from 'react'
import './Input.scss'

//--------------------Libraries----------------------------
import clsx from 'clsx'

const Input = ({
  value,
  onChange = () => {},
  error,
  handleDelete = false,
  label,
  placeholder,
  name,
  formHelper,
  type = 'text',
}) => {
  //---------------------Handlers-----------------------

  const changeHandler = (e) => {
    if (type === 'file') onChange(e.target.files)
    else {
      formHelper
        ? onChange(e.target.name, e.target.value)
        : onChange(e.target.value)
    }
  }

  //---------------------Layout-----------------------

  return (
    <div className={clsx('input', error && 'input_error')}>
      {label && <span className='label'>{label}</span>}
      {type === 'textarea' ? (
        <textarea
          className='inputArea'
          name={name}
          value={value}
          onChange={handleDelete ? onChange : changeHandler}
          placeholder={placeholder}
        />
      ) : (
        <input
          type={type}
          className='inputArea'
          name={name}
          value={value}
          onChange={handleDelete ? onChange : changeHandler}
          placeholder={placeholder}
        />
      )}
      <span className='error'>{error}</span>
    </div>
  )
}

export default Input
