import {
  GET_REPORTS,
  DELETE_REPORT,
  SET_PAGINATION_REPORTS,
  SET_COUNT_REPORTS,
  SET_UNREAD_COUNT
} from '../actions/actionTypes'

const initialSatate = {
  reports: [],
  pagination: 1,
  count: 1,
  badge: 0
}

const reportsReducer = (state = initialSatate, action) => {
  switch (action.type) {
    case DELETE_REPORT: {
      return {
        ...state,
        reports: state.reports.filter((item) => item.id !== action.payload),
      }
    }
    case GET_REPORTS: {
      return { ...state, reports: action.payload }
    }
    case SET_PAGINATION_REPORTS: {
      return { ...state, pagination: action.payload }
    }
    case SET_COUNT_REPORTS: {
      return { ...state, count: action.payload }
    }
    case SET_UNREAD_COUNT: {
      return { ...state, badge: action.payload }
    }
    default:
      return state
  }
}

export default reportsReducer
