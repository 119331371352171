import {
  GET_EXPERTS,
  REMOVE_PRO_STATUS,
  APPROVE_EXPERT,
  SERCH_EXPERTS,
} from '../actions/actionTypes'

const initialSatate = {
  expertsApproved: [],
  expertsNotApproved: [],
  success: false,
  expertsApprovedPage: 1,
  expertsNotApprovedPage: 1,
  loadMoreApprovedExperts: true,
  loadMoreNotApprovedExperts: true,
  search: '',
}

const expertsReducer = (state = initialSatate, action) => {
  switch (action.type) {
    //------------------Get experts--------------------------------------------

    case GET_EXPERTS: {
      const { experts, tab } = action.payload

      if (experts) {
        return {
          ...state,
          [`experts${tab}`]:
            state.expertsPage === 1
              ? [`experts${tab}`]
              : [...state[`experts${tab}`], ...experts],
          [`experts${tab}Page`]: 1 + state[`experts${tab}Page`],
          [`loadMore${tab}Experts`]: experts.length > 0,
        }
      }
      return { ...state, ...action.payload }
    }

    case REMOVE_PRO_STATUS: {
      console.log(action.payload.expert)
      return {
        ...state,
        expertsNotApproved:
          state.expertsNotApproved.length <= 0
            ? []
            : [...state.expertsNotApproved, action.payload.expert],
        expertsApproved: state.expertsApproved.filter(
          (item) => item._id !== action.payload.expert._id
        ),
      }
    }

    case APPROVE_EXPERT: {
      return {
        ...state,
        expertsApproved:
          state.expertsApproved.length <= 0
            ? []
            : [...state.expertsApproved, action.payload.expert],
        expertsNotApproved: state.expertsNotApproved.filter(
          (item) => item._id !== action.payload.expert._id
        ),
      }
    }

    case SERCH_EXPERTS: {
      return {
        ...state,
        search: action.payload,
      }
    }

    default:
      return state
  }
}

export default expertsReducer
