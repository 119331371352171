//------------------Nav bar icons----------------------------
import StatisticsIcon from "./navBar/statisticsIcon";
import AdminsIcon from "./navBar/adminsIcon";
import PROsIcon from "./navBar/prosIcon";
import UsersIcon from "./navBar/usersIcon";
import LogOutIcon from "./navBar/logOutIcon";
import CategoriesIcon from "./navBar/categories";

//------------------Utils icons----------------------------

import CloseIcon from "./utils/closeIcon";
import SmallArrrowIcon from "./utils/smallArrowIcon";
import DeleteIcon from "./utils/deleteIcon";
import UploadIcon from "./utils/uploadIcon";
import CheckIcon from "./utils/checkIcon";
import ReverseArrowIcon from "./utils/reverseArrow";

import FiltersIcon from "./utils/filters";
import StarIcon from "./utils/starIcon";
import Search from "./utils/search";
//-------------------Other icons----------------------------
import Calendar from "./calendar";
import Time from "./time";
import CheckboxActive from "./checkboxActive";
import PaginationRight from "./utils/paginationRight";
import Back from "./utils/back";
import NoPhoto from "./utils/noPhoto";

const ICONS = {
  utils: {
    close: <CloseIcon />,
    smallArrow: <SmallArrrowIcon />,
    delete: <DeleteIcon />,
    upload: <UploadIcon />,
    check: <CheckIcon />,
    search: <Search />,
    filters: <FiltersIcon />,
    star: <StarIcon />,
    paginationRight: <PaginationRight />,
    back: <Back />,
    noPhoto: <NoPhoto />,
    ReverseArrowIcon,
  },
  navBar: {
    categories: <CategoriesIcon />,
    users: <UsersIcon />,
    PROs: <PROsIcon />,
    admins: <AdminsIcon />,
    statistics: <StatisticsIcon />,
    logOut: <LogOutIcon />,
  },
  calendar: <Calendar />,
  time: <Time />,
  checkboxActive: <CheckboxActive />,
};

export default ICONS;
