const StarIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.27 17.2682L16.7659 19.8828C17.5892 20.362 18.5967 19.6536 18.38 18.7578L17.1884 13.8411L21.1642 10.5286C21.89 9.92448 21.5 8.77865 20.5467 8.70573L15.3142 8.27865L13.2667 3.63281C12.8984 2.78906 11.6417 2.78906 11.2734 3.63281L9.22587 8.26823L3.99337 8.69531C3.04004 8.76823 2.65004 9.91406 3.37587 10.5182L7.35171 13.8307L6.16004 18.7474C5.94337 19.6432 6.95087 20.3516 7.7742 19.8724L12.27 17.2682Z"
      fill="#FABC41"
    />
  </svg>
);

export default StarIcon;
