const DeleteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8573 8.57141V17.5714C18.8573 19.7017 17.1304 21.4286 15.0001 21.4286H9.00014C6.8699 21.4286 5.143 19.7017 5.143 17.5714V8.57141H4.53459C3.92383 8.57141 3.42871 8.07629 3.42871 7.46553V6.21427C3.42871 4.91245 4.48404 3.85713 5.78585 3.85713H7.20113C7.65002 3.08005 8.4885 2.57141 9.42871 2.57141H14.5716C15.5118 2.57141 16.3503 3.08005 16.7991 3.85713H18.2144C19.5162 3.85713 20.5716 4.91245 20.5716 6.21427V7.46553C20.5716 8.07629 20.0764 8.57141 19.4657 8.57141H18.8573ZM18.0001 6.85713H18.8573V6.21427C18.8573 5.85923 18.5695 5.57141 18.2144 5.57141H16.2318C15.8407 5.57141 15.4992 5.30667 15.4018 4.92792C15.3053 4.55321 14.9649 4.2857 14.5716 4.2857H9.42871C9.0354 4.2857 8.69496 4.55321 8.59852 4.92792C8.50104 5.30667 8.15953 5.57141 7.76843 5.57141H5.78585C5.43081 5.57141 5.143 5.85923 5.143 6.21427V6.85713H6.00014H18.0001ZM6.85728 8.57141V17.5714C6.85728 18.7549 7.81667 19.7143 9.00014 19.7143H15.0001C16.1836 19.7143 17.143 18.7549 17.143 17.5714V8.57141H6.85728Z"
      fill="#505050"
    />
  </svg>
);

export default DeleteIcon;
