import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

const data = [
  {
    name: 'Aug',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Aug',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Aug',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Aug',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Aug',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Aug',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Aug',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Aug',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Aug',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Aug',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Aug',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Aug',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
]

const Graph = () => {
  //   static demoUrl = "https://codesandbox.io/s/simple-bar-chart-tpz8r";

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        <Bar dataKey='pv' fill='#5444EB' />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default Graph
