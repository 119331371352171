const CheckboxActive = () => (
  <svg
    width='12'
    height='10'
    viewBox='0 0 12 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.41891 9.16458C4.16424 9.16458 3.90691 9.06725 3.71224 8.87125L0.546906 5.70725C0.15624 5.31658 0.15624 4.68458 0.546906 4.29391C0.937573 3.90325 1.56957 3.90325 1.96024 4.29391L4.41891 6.74991L10.0402 1.12991C10.4309 0.739247 11.0629 0.739247 11.4536 1.12991C11.8442 1.52058 11.8442 2.15258 11.4536 2.54325L5.12557 8.87125C4.93091 9.06725 4.67491 9.16458 4.41891 9.16458'
      fill='white'
    />
  </svg>
)

export default CheckboxActive
