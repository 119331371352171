export const page = [
  { id: 2, title: 'Home', label: 'home' },
  { id: 3, title: 'Chat', label: 'chat' },
]

export const pageAdmin = [
  { id: 1, title: 'All' },
  { id: 2, title: 'Home', label: 'home' },
  { id: 3, title: 'Chat', label: 'chat' },
]

export const tagsOptions = [
  { label: 'Bear', title: 'Bear' },
  { label: 'Otter', title: 'Otter' },
  { label: 'Chaser', title: 'Chaser' },
  { label: 'Cub', title: 'Cub' },
  { label: 'Polar Bear', title: 'Polar Bear' },
  { label: 'Wolf', title: 'Wolf' },
  { label: 'Gainer', title: 'Gainer' },
  { label: 'Admirer', title: 'Admirer' },
  { label: 'Bloater', title: 'Bloater' },
  { label: 'Maintainer', title: 'Maintainer' },
  { label: 'Muscle Gainer', title: 'Muscle Gainer' },
  { label: 'Chubby', title: 'Chubby' },
  { label: 'Feeder', title: 'Feeder' },
  { label: 'Feedee', title: 'Feedee' },
  { label: 'Daddy', title: 'Daddy' },
  { label: 'Discrete', title: 'Discrete' },
  { label: 'Geek', title: 'Geek' },
  { label: 'Jock', title: 'Jock' },
  { label: 'Leather', title: 'Leather' },
  { label: 'Poz', title: 'Poz' },
  { label: 'Rugged', title: 'Rugged' },
  { label: 'Trans', title: 'Trans' },
  { label: 'Twink', title: 'Twink' },
  { label: 'Sober', title: 'Sober' },
  { label: 'Other', title: 'Other' },
]
export const tagsOptionsAdmin = [
  { label: 'All Categories', title: 'All Categories' },
  { label: 'Bear', title: 'Bear' },
  { label: 'Otter', title: 'Otter' },
  { label: 'Chaser', title: 'Chaser' },
  { label: 'Cub', title: 'Cub' },
  { label: 'Polar Bear', title: 'Polar Bear' },
  { label: 'Wolf', title: 'Wolf' },
  { label: 'Gainer', title: 'Gainer' },
  { label: 'Admirer', title: 'Admirer' },
  { label: 'Bloater', title: 'Bloater' },
  { label: 'Maintainer', title: 'Maintainer' },
  { label: 'Muscle Gainer', title: 'Muscle Gainer' },
  { label: 'Chubby', title: 'Chubby' },
  { label: 'Feeder', title: 'Feeder' },
  { label: 'Feedee', title: 'Feedee' },
  { label: 'Daddy', title: 'Daddy' },
  { label: 'Discrete', title: 'Discrete' },
  { label: 'Geek', title: 'Geek' },
  { label: 'Jock', title: 'Jock' },
  { label: 'Leather', title: 'Leather' },
  { label: 'Poz', title: 'Poz' },
  { label: 'Rugged', title: 'Rugged' },
  { label: 'Trans', title: 'Trans' },
  { label: 'Twink', title: 'Twink' },
  { label: 'Sober', title: 'Sober' },
  { label: 'Other', title: 'Other' },
]

export const type = [
  { id: 1, title: 'Full screen Ad', label: 'fullScreen' },
  { id: 2, title: 'Banner', label: 'banner' },
]

export const genderOptions = [
  { title: 'Man', label: 'Man' },
  { title: 'Cis Man', label: 'Cis Man' },
  { title: 'Trans Man', label: 'Trans Man' },
  { title: 'Woman', label: 'Woman' },
  { title: 'Cis Woman', label: 'Cis Woman' },
  { title: 'Trans Woman', label: 'Trans Woman' },
  { title: 'Non-Binary', label: 'Non-Binary' },
  { title: 'Non-Conforming', label: 'Non-Conforming' },
  { title: 'Queer', label: 'Queer' },
  { title: 'Crossdresser', label: 'Crossdresser' },
  { title: 'Two-Spirit', label: 'Two-Spirit' },
  { title: 'Not Specified', label: 'Not Specified' },
]

export const lastAds = [
  { title: 'All days' },
  { title: 'Last 7 days', label: 7 },
  { title: 'Last 15 days', label: 15 },
  { title: 'Last 30 days', label: 30 },
  { title: 'Last 3 mounth', label: 90 },
]
