const CategoriesIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 10.5C3.17 10.5 2.5 11.17 2.5 12C2.5 12.83 3.17 13.5 4 13.5C4.83 13.5 5.5 12.83 5.5 12C5.5 11.17 4.83 10.5 4 10.5ZM4 4.5C3.17 4.5 2.5 5.17 2.5 6C2.5 6.83 3.17 7.5 4 7.5C4.83 7.5 5.5 6.83 5.5 6C5.5 5.17 4.83 4.5 4 4.5ZM4 16.5C3.16541 16.5 2.5 17.1767 2.5 18C2.5 18.8233 3.17669 19.5 4 19.5C4.82331 19.5 5.5 18.8233 5.5 18C5.5 17.1767 4.83459 16.5 4 16.5ZM21 18C21 17.4477 20.5523 17 20 17H8C7.44772 17 7 17.4477 7 18C7 18.5523 7.44772 19 8 19H20C20.5523 19 21 18.5523 21 18ZM21 12C21 11.4477 20.5523 11 20 11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H20C20.5523 13 21 12.5523 21 12ZM8 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H8C7.44772 5 7 5.44772 7 6C7 6.55228 7.44772 7 8 7Z"
      fill="#808191"
    />
  </svg>
);

export default CategoriesIcon;
