import * as yup from 'yup'

const FILE_SIZE = 5000000

const SUPPORTED_FORMATS = ['image/png']

const validationValues = {
  email: yup.string().email().required('Required!'),

  password: yup.string().min(5, 'Too short!').required('Required!'),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Must be the same as password!')
    .required('Required!'),

  categoryTitle: yup
    .string()
    .min(3, 'Too short!')
    .required('Required!')
    .max(30, 'Too long!'),

  subCategories: yup
    .array()
    .of(
      yup
        .string()
        .min(3, 'Too short!')
        .required('Required!')
        .max(30, 'Too long!')
    ),

  editSubCategories: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .min(3, 'Too short!')
        .required('Required!')
        .max(30, 'Too long!'),
      id: yup.string(),
    })
  ),

  photo: yup
    .mixed()
    .required('File is required')
    .test('size', 'File size is too large!', (value) => {
      if (value) return value.size <= FILE_SIZE
    })
    .test('type', 'Unsupported file format!', (value) => {
      if (value) return SUPPORTED_FORMATS.includes(value.type)
    }),
}

export default validationValues
