import {
  GET_USERS,
  DELETE_USER,
  SET_PAGINATION,
  SET_COUNT,
} from '../actions/actionTypes'

const initialSatate = {
  users: [],
  pagination: 1,
  count: 1,
}

const usersReducer = (state = initialSatate, action) => {
  switch (action.type) {
    case DELETE_USER: {
      return {
        ...state,
        users: state.users.filter((item) => item.id !== action.payload),
      }
    }
    case GET_USERS: {
      return { ...state, users: action.payload }
    }
    // case RESTRICT_USER: {
    //   return { ...state, users: action.payload }
    // }
    case SET_PAGINATION: {
      return { ...state, pagination: action.payload }
    }
    case SET_COUNT: {
      return { ...state, count: action.payload }
    }
    default:
      return state
  }
}

export default usersReducer
