const Pensil = () => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5 15.5L1.5 16.5L2.5 11.5L12.5 1.5L16.5 5.5L6.5 15.5V15.5Z'
      stroke='#11142D'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Pensil
