import * as yup from 'yup'
import validationValues from '../values'

const addAdminSchema = yup.object().shape({
  email: validationValues.email,
  password: validationValues.password,
  confirmPassword: validationValues.confirmPassword,
})

export default addAdminSchema
