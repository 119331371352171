import {
  CREATE_CATEGORIES,
  DELETE_CATEGORIES,
  GET_CATEGORIES,
  EDIT_CATEGORIES,
  EDIT_SUBCATEGORIES,
  DELETE_SUBCATEGORIES,
  CREATE_SUBCATEGORIES,
} from "../actions/actionTypes";

const initialSatate = {
  categories: [],
  success: false,
  categoriesPage: 1,
  loadMoreCategories: true,
  categoryToEdit: null,
};

const categoriesReducer = (state = initialSatate, action) => {
  switch (action.type) {
    //------------------Create Categories--------------------------------------------

    case CREATE_CATEGORIES: {
      return { ...state, ...action.payload };
    }

    //------------------Edit Categories--------------------------------------------

    case EDIT_CATEGORIES: {
      const { newCategory, loading } = action.payload;

      let newState;

      if (newCategory) {
        newState = {
          ...state,
          categories: state.categories.map((item) => {
            if (item._id === state.categoryToEdit._id) return newCategory;
            else return item;
          }),
          loading: loading,
          categoryToEdit: newCategory,
        };
      } else newState = { ...state, ...action.payload };

      return newState;
    }

    //------------------Create subCategories--------------------------------------------

    case CREATE_SUBCATEGORIES: {
      const { category } = action.payload;

      let newState;

      if (category) {
        const newCategory = {
          ...state.categoryToEdit,
          categories: [...state.categoryToEdit.categories, category],
        };

        newState = {
          ...state,
          categories: state.categories.map((item) => {
            if (item._id === state.categoryToEdit._id) return newCategory;
            else return item;
          }),
          categoryToEdit: newCategory,
        };
      } else newState = state;

      return newState;
    }

    //------------------Edit subCategories--------------------------------------------

    case EDIT_SUBCATEGORIES: {
      const { _id, name } = action.payload;

      let newState;

      if (_id) {
        const newCategory = {
          ...state.categoryToEdit,
          categories: state.categoryToEdit.categories.map((item) => {
            if (item._id === _id) return { ...item, name: name };
            else return item;
          }),
        };

        newState = {
          ...state,
          categories: state.categories.map((item) => {
            if (item._id === state.categoryToEdit._id) return newCategory;
            else return item;
          }),
          categoryToEdit: newCategory,
        };
      } else newState = state;

      return newState;
    }

    //------------------Delete subCategories--------------------------------------------

    case DELETE_SUBCATEGORIES: {
      const { _id } = action.payload;

      let newState;

      if (_id) {
        const newCategory = {
          ...state.categoryToEdit,
          categories: state.categoryToEdit.categories.filter(
            (item) => item._id !== _id
          ),
        };

        newState = {
          ...state,
          categories: state.categories.map((item) => {
            if (item._id === state.categoryToEdit._id) return newCategory;
            else return item;
          }),
          categoryToEdit: newCategory,
        };
      } else newState = state;

      return newState;
    }

    //------------------Delete Categories--------------------------------------------

    case DELETE_CATEGORIES: {
      const { _id } = action.payload;

      let newState;

      if (_id) {
        const newCategories = state.categories.filter(
          (item) => item._id !== _id
        );

        newState = {
          ...state,
          categories: newCategories,
        };
      } else newState = state;

      return newState;
    }

    //------------------Get Categories--------------------------------------------

    case GET_CATEGORIES: {
      const { categories } = action.payload;

      if (categories) {
        return {
          ...state,
          categories:
            state.categoriesPage === 1
              ? categories
              : [...state.categories, ...categories],
          categoriesPage: 1 + state.categoriesPage,
          loadMoreCategories: categories.length > 0,
        };
      }
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};

export default categoriesReducer;
