//-----------Basic imports----------------------------
import React, { useEffect, useState } from 'react'
import styles from './Reports.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'
import clsx from 'clsx'

import { ReportItem } from '../../components'
import ICONS from '../../assets/icons'
import ReportsAPI from '../../APIs/reports'
import {
  GET_REPORTS,
  SET_COUNT_REPORTS,
  SET_PAGINATION_REPORTS,
  SET_UNREAD_COUNT,
} from '../../redux/actions/actionTypes'
import PaginationLeft from '../../assets/icons/utils/paginationLeft'
import PaginationRight from '../../assets/icons/utils/paginationRight'

const Reports = () => {
  const [reportState, setReportState] = useState('active')
  const [sortState, setSortState] = useState(0)
  const [sortType, setSortType] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const infoTabs = ['Complaint Date', 'Sent by', 'Report to']
  const sortTabs = ['createdAt', 'reporter', 'reported']

  const { reports, pagination, count } = useSelector(
    (state) => state.reportsReducer
  )

  const dispatch = useDispatch()

  const getReports = async () => {
    try {
      setIsLoading(true)
      const sortBy = `${sortType ? '' : '-'}${sortTabs[sortState]}`

      const res = await ReportsAPI.getReports(
        reportState,
        pagination,
        sortBy
        // sortTabs[sortState]
      )
      console.log('res', res)
      console.log('reportState', reportState)
      dispatch({ type: GET_REPORTS, payload: res.rows })
      dispatch({ type: SET_COUNT_REPORTS, payload: res.count })
    } catch (e) {
      console.log('e', e)
    } finally {
      setIsLoading(false)
    }
  }

  const readReports = async () => {
    try {
      await ReportsAPI.readReports()
      dispatch({ type: SET_UNREAD_COUNT, payload: 0 })
    } catch (e) {
      console.log('e', e)
    }
  }

  const changeSort = (newIndex) => {
    setSortState(newIndex)

    if (newIndex !== sortState) {
      setSortType(false)
    } else {
      setSortType(!sortType)
    }
    dispatch({ type: SET_PAGINATION_REPORTS, payload: 1 })
  }

  useEffect(() => {
    readReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, count, reportState, sortState, sortType])

  const addPage = () => {
    if (pagination < Math.ceil(count / 20)) {
      dispatch({ type: SET_PAGINATION_REPORTS, payload: pagination + 1 })
    } else {
      return
    }
  }

  const prevPage = () => {
    if (pagination > 1) {
      dispatch({ type: SET_PAGINATION_REPORTS, payload: pagination - 1 })
    } else {
      return
    }
  }

  return (
    <div className={styles.users}>
      <div className={styles.users__header}>
        <div className={styles.bigTitle}>Reports</div>
        <div className={styles.reportsBlock__switcher}>
          <div
            className={clsx(styles.reportsBlock__switcherBg, {
              [styles.reportsBlock__switcherBgMiddle]:
                reportState === 'inProgress',
              [styles.reportsBlock__switcherBgRight]:
                reportState === 'completed',
            })}
          />
          <div
            onClick={() => {
              setReportState('active')
              dispatch({ type: SET_PAGINATION_REPORTS, payload: 1 })
            }}
            className={clsx(styles.reportsBlock__switcherItem, {
              [styles.active]: reportState === 'active',
            })}
          >
            Reports
          </div>
          <div
            onClick={() => {
              setReportState('inProgress')
              dispatch({ type: SET_PAGINATION_REPORTS, payload: 1 })
            }}
            className={clsx(styles.reportsBlock__switcherItem, {
              [styles.active]: reportState === 'inProgress',
            })}
          >
            In Progress
          </div>
          <div
            onClick={() => {
              setReportState('completed')
              dispatch({ type: SET_PAGINATION_REPORTS, payload: 1 })
            }}
            className={clsx(styles.reportsBlock__switcherItem, {
              [styles.active]: reportState === 'completed',
            })}
          >
            Completed
          </div>
        </div>
      </div>

      <div className={styles.users__infoBar}>
        {infoTabs.map((item, index) => (
          <div
            // className={styles.users__infoBar_item}
            className={clsx(styles.users__infoBar_item, {
              [styles.users__infoBar_item_active]: sortState === index,
            })}
            onClick={() => changeSort(index)}
            key={index}
          >
            <p className={styles.users__infoBar_item_title}>{item}</p>
            <ICONS.utils.ReverseArrowIcon
              color={sortState === index ? 'black' : '#b2b3bd'}
            />
          </div>
        ))}

        {isLoading && (
          <div className={styles.users__infoBar_loading}>
            <ClipLoader size={15} color='#57b6ed' />
          </div>
        )}
      </div>

      <div className={styles.users__content}>
        <ul>
          {reports.map((item, index) => (
            <ReportItem key={index} item={item} getReports={getReports} />
          ))}
        </ul>
      </div>
      <div className={styles.pagination}>
        <div className={styles.paginationArrow} onClick={prevPage}>
          {PaginationLeft(
            pagination > 1 ? '#404247' : 'rgba(43, 45, 51, 0.24)'
          )}
        </div>
        <p className={styles.paginationCurrent}>{pagination}</p>
        <p>of</p>
        <p className={styles.paginationAll}>{Math.ceil(count / 20)}</p>
        <div className={styles.paginationArrow} onClick={addPage}>
          {PaginationRight(
            pagination < Math.ceil(count / 20)
              ? '#404247'
              : 'rgba(43, 45, 51, 0.24)'
          )}
        </div>
      </div>
    </div>
  )
}

export default Reports
