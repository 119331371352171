import axios from 'axios'
import { API_ENDPOINT } from '../consts/urls'

const apiClient = axios.create({
  baseURL: API_ENDPOINT,
})

apiClient.interceptors.request.use(async (config) => {
  const token = 'uNbkL6KLxwethVYLDKoMGgXgtNHoFcOY'

  if (token && !config.headers.Authorization) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  return config
})

export default apiClient
