const ROUTES = {
  ads: '/ads',
  reports: '/reports',
  users: {
    page: '/users',
    all: '/users/all',
    id: '/users/all/:id',
    requests: '/users/requests',
  },
  statistics: {
    page: '/statistics',
    list: '/statistics/list',
    addCategory: '/statistics/add',
  },
  admins: {
    page: '/admins',
  },
  signIn: '/signIn',
  home: '/',
  notFound: '*',
}

export default ROUTES
