const StatisticsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7142 18C20.1876 18 20.5713 18.3837 20.5713 18.8571C20.5713 19.3305 20.1876 19.7143 19.7142 19.7143H4.28561C3.81222 19.7143 3.42847 19.3305 3.42847 18.8571C3.42847 18.3837 3.81222 18 4.28561 18H19.7142ZM18.857 5.14282C19.3304 5.14282 19.7142 5.52658 19.7142 5.99997V10.2857C19.7142 10.7591 19.3304 11.1428 18.857 11.1428C18.3837 11.1428 17.9999 10.7591 17.9999 10.2857V8.06911L12.606 13.4632C12.297 13.7722 11.8108 13.796 11.4746 13.5345L11.3938 13.4632L9.42847 11.4979L5.74884 15.1775C5.41411 15.5122 4.8714 15.5122 4.53666 15.1775C4.20193 14.8428 4.20193 14.3 4.53666 13.9653L8.82238 9.67959C9.15711 9.34485 9.69982 9.34485 10.0346 9.67959L11.9999 11.6449L16.7862 6.85711H14.5713C14.1318 6.85711 13.7695 6.52622 13.7199 6.09993L13.7142 5.99997C13.7142 5.52658 14.0979 5.14282 14.5713 5.14282H18.857Z"
      fill="white"
    />
  </svg>
);

export default StatisticsIcon;
