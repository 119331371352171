//-----------Basic imports----------------------------
import React from 'react'
import styles from './Admins.module.scss'

//-----------Libraries----------------------------
import { useSelector, useDispatch } from 'react-redux'

import AdminsApi from '../../APIs/admins'
import { Formik } from 'formik'

//-------------Validations-----------------------------
import VALIDATIONS from '../../validation'

//------------Components-----------------------------
import { Button, Input, Loader } from '../../components'

import { useState } from 'react'
import { useEffect } from 'react'
import {
  CREATE_ADMINS,
  DELETE_ADMINS,
  GET_ADMINS,
  SET_COUNT_ADMIN,
  SET_PAGINATION_ADMIN,
} from '../../redux/actions/actionTypes'
import PaginationLeft from '../../assets/icons/utils/paginationLeft'
import PaginationRight from '../../assets/icons/utils/paginationRight'
import DeleteModal from '../../components/DeleteModal'
//---------------Subpages---------------------------

const AdminCard = ({ data, setOpenModal, openModal }) => {
  //----------------Hooks--------------------------------

  const [loading] = React.useState(false)

  const dispatch = useDispatch()

  //----------------Data--------------------------------

  const { email, id } = data

  const deleteAdmin = async () => {
    try {
      await AdminsApi.deleteAdmin(id)
      dispatch({ type: DELETE_ADMINS, payload: id })
      setOpenModal(false)
    } catch (e) {
      console.log('e', e)
    }
  }

  return (
    <>
      <li className={styles.adminsList__item}>
        <p>{email}</p>
        {loading ? (
          <div className={styles.adminsList__loader}>
            <Loader />
          </div>
        ) : (
          <Button onClick={() => setOpenModal(true)}>
            <span>Delete</span>
          </Button>
        )}
      </li>
      <DeleteModal
        show={openModal}
        close={() => setOpenModal(false)}
        onClick={deleteAdmin}
        title='Delete this admin'
        description='Are you sure you want to delete this admin permanently?'
      />
    </>
  )
}

const BearsAdmins = () => {
  const [openModal, setOpenModal] = useState(false)
  const { admins, pagination, count } = useSelector(
    (state) => state.adminsReducer
  )
  const { loading } = useSelector((state) => state.authReducer)
  const dispatch = useDispatch()

  const getAdmins = async () => {
    try {
      const res = await AdminsApi.getAdmins(pagination)
      dispatch({ type: GET_ADMINS, payload: res.rows })
      dispatch({ type: SET_COUNT_ADMIN, payload: res.count })
    } catch (err) {
      console.log('err', err)
    }
  }

  useEffect(() => {
    getAdmins()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, count])

  const addAdmin = async (email, password, handleChange) => {
    const data = {
      email: email,
      password: password,
    }
    try {
      const res = await AdminsApi.createAdmin(data)
      dispatch({ type: CREATE_ADMINS, payload: res })
      handleChange('email')('')
      handleChange('password')('')
      handleChange('confirmPassword')('')
    } catch (err) {
      console.log('err', err)
    }
  }

  const addPage = () => {
    if (pagination < Math.ceil(count / 20)) {
      dispatch({ type: SET_PAGINATION_ADMIN, payload: pagination + 1 })
    } else {
      return
    }
  }

  const prevPage = () => {
    if (pagination > 1) {
      dispatch({ type: SET_PAGINATION_ADMIN, payload: pagination - 1 })
    } else {
      return
    }
  }

  return (
    <>
      <div className={styles.adminRow}>
        <div className={styles.adminsNew}>
          <div className={styles.bigTitle}> Create admin</div>
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{ email: '', password: '', confirmPassword: '' }}
            validationSchema={VALIDATIONS.addAdminSchema}
            onSubmit={console.log('YESSER')}
          >
            {({ errors, values, handleSubmit, handleChange }) => (
              <form
                id='createAdminForm'
                onSubmit={handleSubmit}
                className={styles.addAdmin}
              >
                <Input
                  formHelper
                  label='Email'
                  name='email'
                  placeholder='Enter email'
                  value={values.email}
                  error={errors.email}
                  onChange={(value) => {
                    handleChange('email')(value)
                    delete errors.email
                  }}
                  handleDelete
                />
                <Input
                  formHelper
                  type='password'
                  label='Password'
                  name='password'
                  placeholder='Enter password'
                  value={values.password}
                  error={errors.password}
                  onChange={(value) => {
                    handleChange('password')(value)
                    delete errors.password
                  }}
                  handleDelete
                />
                <Input
                  formHelper
                  type='password'
                  label='Confirm password'
                  name='confirmPassword'
                  placeholder='confirm your password'
                  value={values.confirmPassword}
                  error={errors.confirmPassword}
                  onChange={(value) => {
                    handleChange('confirmPassword')(value)
                    delete errors.confirmPassword
                  }}
                  handleDelete
                />
                {loading ? (
                  <div className={styles.addAdmin__loader}>
                    <Loader />
                  </div>
                ) : (
                  <div className={styles.createBtnCase}>
                    <Button
                      type='submit'
                      onClick={() =>
                        addAdmin(values.email, values.password, handleChange)
                      }
                    >
                      <span>Create admin</span>
                    </Button>
                  </div>
                )}
              </form>
            )}
          </Formik>
        </div>
        <div className={styles.admins}>
          <div className={styles.bigTitle}> Admins</div>
          <div className={styles.smallTitle}>Email</div>
          <div className={styles.adminsList}>
            <ul>
              {admins?.map((item) => (
                <AdminCard
                  key={item.id}
                  data={item}
                  setOpenModal={setOpenModal}
                  openModal={openModal}
                />
              ))}
            </ul>
          </div>
          <div className={styles.pagination}>
            <div className={styles.paginationArrow} onClick={prevPage}>
              {PaginationLeft(
                pagination > 1 ? '#404247' : 'rgba(43, 45, 51, 0.24)'
              )}
            </div>
            <p className={styles.paginationCurrent}>{pagination}</p>
            <p>of</p>
            <p className={styles.paginationAll}>{Math.ceil(count / 20)}</p>
            <div className={styles.paginationArrow} onClick={addPage}>
              {PaginationRight(
                pagination < Math.ceil(count / 20)
                  ? '#404247'
                  : 'rgba(43, 45, 51, 0.24)'
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BearsAdmins
