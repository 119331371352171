import {
  loginSchema,
  addCategorySchema,
  addAdminSchema,
  editCategorySchema,
  createAdvSchema,
} from './schemas'

const VALIDATIONS = {
  loginSchema,
  addCategorySchema,
  addAdminSchema,
  editCategorySchema,
  createAdvSchema,
}

export default VALIDATIONS
