//---------------------Basic imports-----------------------
import React from 'react'
import './ReportItem.scss'
import moment from 'moment'
import DeleteModal from '../DeleteModal'
import ArchiveModal from '../ArchiveModal'
import ReportsAPI from '../../APIs/reports'
import { useDispatch } from 'react-redux'
import { DELETE_REPORT } from '../../redux/actions/actionTypes'
import Modal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0',
    padding: '0',
    background: '0',
    // width: '400px',
    // height: '400px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

const Reports = ({ item }) => {
  //---------------------Hooks-----------------------

  const dispatch = useDispatch()

  const [ban, setBan] = React.useState(false)
  const [isShowArchiveModal, setIsShowArchiveModal] = React.useState(false)
  const [isShowInProgressModal, setIsShowInProgressModal] =
    React.useState(false)
  const [modalIsOpen, setIsOpen] = React.useState(null)

  const archiveReport = async () => {
    try {
      await ReportsAPI.archiveReport(item?.id)
      setIsShowArchiveModal(false)
      dispatch({ type: DELETE_REPORT, payload: item?.id })
    } catch (err) {
      console.log('err', err)
    } finally {
      setIsShowArchiveModal(false)
    }
  }
  const inProgressReport = async () => {
    try {
      await ReportsAPI.inProgressReport(item?.id)
      setIsShowInProgressModal(false)
      dispatch({ type: DELETE_REPORT, payload: item?.id })
    } catch (err) {
      console.log('err', err)
    } finally {
      setIsShowInProgressModal(false)
    }
  }

  const deleteReport = async () => {
    try {
      await ReportsAPI.deleteReport(item?.id)
      setBan(false)
      dispatch({ type: DELETE_REPORT, payload: item?.id })
    } catch (err) {
      console.log('err', err)
    }
  }

  const closeModal = () => {
    setIsOpen(null)
  }

  console.log('item?.reported', item?.reported)

  return (
    <>
      <li>
        <div className='usersCard'>
          <div>{moment(item?.createdAt).format('MM/DD/YYYY')}</div>
          <div>
            <p>{item?.reporter?.name}</p>
            <p style={{ color: 'gray' }}>
              {item?.reporter?.id
                ? `(${item?.reporter?.id})`
                : 'This user has been deleted'}
            </p>
          </div>
          <div>
            <p>{item?.reported?.name}</p>
            <p style={{ color: 'gray' }}>
              {item?.reported?.id
                ? `(${item?.reported?.id})`
                : 'This user has been deleted'}
            </p>
          </div>
          {item?.status === 'active' && (
            <div className='btn-wrapper'>
              <button
                onClick={() => setIsShowArchiveModal(true)}
                type='button'
                className='myClassButton2'
              >
                Completed
              </button>
              <button
                onClick={() => setIsShowInProgressModal(true)}
                type='button'
                className='myClassButton2'
              >
                In Progress
              </button>

              <button
                onClick={() => setBan(true)}
                type='button'
                className='myClassButton2'
              >
                Delete
              </button>
            </div>
          )}
          {item?.status === 'inProgress' && (
            <div className='btn-wrapper'>
              <button
                onClick={() => setIsShowArchiveModal(true)}
                type='button'
                className='myClassButton2'
              >
                Completed
              </button>

              <button
                onClick={() => setBan(true)}
                type='button'
                className='myClassButton2'
              >
                Delete
              </button>
            </div>
          )}
          {item?.status === 'completed' && (
            <div className='btn-wrapper'>
              <button
                onClick={() => setBan(true)}
                type='button'
                className='myClassButton2'
              >
                Delete
              </button>
            </div>
          )}
        </div>
        <p className='titleText'>Reason:</p>
        <div className='textBanMod'>{item?.reason}</div>
        <p className='titleText'>Text:</p>
        <div className='textBanMod'>{item?.text}</div>
        {item.attachments.length ? (
          <div className='photosWrapper'>
            <p className='titleText'>Attachments:</p>
            {item.attachments.map((photo) => (
              <img
                alt='avatar'
                src={photo}
                className='reportPhoto'
                onClick={() => setIsOpen(photo)}
              />
            ))}
          </div>
        ) : null}
      </li>
      <DeleteModal
        title='Delete this report?'
        description='Are you sure you want to delete this report?'
        show={ban}
        close={() => setBan(false)}
        onClick={deleteReport}
        data={item}
      />
      <ArchiveModal
        title='Completed this report?'
        description='Are you sure you want to completed this report?'
        show={isShowArchiveModal}
        btn={'Completed'}
        onClose={() => setIsShowArchiveModal(false)}
        onClick={archiveReport}
        data={item}
      />
      <ArchiveModal
        title='In Progress this report?'
        description='Are you sure you want to in progress this report?'
        show={isShowInProgressModal}
        btn={'In Progress'}
        onClose={() => setIsShowInProgressModal(false)}
        onClick={inProgressReport}
        data={item}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <img
          alt='avatar'
          src={modalIsOpen}
          className='reportMainPhoto'
          onClick={() => setIsOpen(null)}
        />
      </Modal>
    </>
  )
}

export default Reports
