//-----------------Basic imports------------------------
import React from "react";
import "./CustomCalendar.scss";
import "react-calendar/dist/Calendar.css";

//-----------------Libraries------------------------
import Calendar from "react-calendar";
import clsx from "clsx";

const CustomCalendar = ({ show = false, onClickDay = () => {}, date }) => {
  //-------------------Handlers-------------------------------

  const dayClickHandler = (value) => {
    onClickDay(value);
  };

  //--------------Layout--------------------------------------
  return (
    <Calendar
      className={clsx(show && "react-calendar_rendered")}
      next2Label={null}
      prev2Label={null}
      calendarType="US"
      locale="en"
      onChange={dayClickHandler}
      value={date}
    />
  );
};

export default CustomCalendar;
