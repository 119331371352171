//-------------------Basic imports------------------------------------------------
import React from 'react'
import { Switch, Route } from 'react-router-dom'

//-------------------Pages------------------------------------------------
import { Home, SignIn } from '../pages'
import NotFound from '../pages/NotFound'
import PrivateRoute from './privateRoute'

//-------------------Routes------------------------------------------------
import ROUTES from './routes'

function MainRouter() {
  //-------------------Hooks------------------------------------------------

  //-------------------Layout------------------------------------------------

  return (
    <>
      <Switch>
        <Route path={ROUTES.signIn} component={SignIn} />
        <Route
          path={ROUTES.home}
          render={(props) => (
            <PrivateRoute {...props}>
              <Home />
            </PrivateRoute>
          )}
        />
        <Route path={ROUTES.notFound} component={NotFound} />
      </Switch>
    </>
  )
}

export default MainRouter
