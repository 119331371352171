const ReverseArrowIcon = ({ color = "black" }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 800 800"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M400 133.333V666.667M400 133.333L516.667 250M400 133.333L283.333 250M400 666.667L283.333 550M400 666.667L516.667 550"
      stroke={color}
      stroke-width="66.6667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ReverseArrowIcon;
