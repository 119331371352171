const PROsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clillrule="evenodd"
      d="M7.00001 14C7.49706 14 7.90001 14.403 7.90001 14.9V19.1H16.1V14.9C16.1 14.403 16.5029 14 17 14C17.4971 14 17.9 14.403 17.9 14.9V20C17.9 20.4971 17.4971 20.9 17 20.9H7.00001C6.50295 20.9 6.10001 20.4971 6.10001 20V14.9C6.10001 14.403 6.50295 14 7.00001 14Z"
      fill="#808191"
    />
    <path
      fillRule="evenodd"
      clillrule="evenodd"
      d="M3 20C3 19.5029 3.40294 19.1 3.9 19.1H20.1C20.5971 19.1 21 19.5029 21 20C21 20.4971 20.5971 20.9 20.1 20.9H3.9C3.40294 20.9 3 20.4971 3 20Z"
      fill="#808191"
    />
    <path
      fillRule="evenodd"
      clillrule="evenodd"
      d="M16.9 10C16.9 10.6075 17.3925 11.1 18 11.1C18.6075 11.1 19.1 10.6075 19.1 10H20.9C20.9 11.6016 19.6016 12.9 18 12.9C16.3984 12.9 15.1 11.6016 15.1 10H16.9Z"
      fill="#808191"
    />
    <path
      fillRule="evenodd"
      clillrule="evenodd"
      d="M16 9.10001C16.4971 9.10001 16.9 9.50294 16.9 10C16.9 10.6075 17.3925 11.1 18 11.1C18.6075 11.1 19.1 10.6075 19.1 10C19.1 9.50294 19.5029 9.10001 20 9.10001C20.4971 9.10001 20.9 9.50294 20.9 10C20.9 11.6016 19.6016 12.9 18 12.9C16.3984 12.9 15.1 11.6016 15.1 10C15.1 9.50294 15.5029 9.10001 16 9.10001Z"
      fill="#808191"
    />
    <path
      fillRule="evenodd"
      clillrule="evenodd"
      d="M12 9.10001C12.4971 9.10001 12.9 9.50295 12.9 10C12.9 10.6075 13.3925 11.1 14 11.1C14.6075 11.1 15.1 10.6075 15.1 10C15.1 9.50294 15.5029 9.10001 16 9.10001C16.4971 9.10001 16.9 9.50294 16.9 10C16.9 11.6016 15.6016 12.9 14 12.9C12.3984 12.9 11.1 11.6016 11.1 10C11.1 9.50295 11.5029 9.10001 12 9.10001Z"
      fill="#808191"
    />
    <path
      fillRule="evenodd"
      clillrule="evenodd"
      d="M8.00001 9.10001C8.49706 9.10001 8.90001 9.50295 8.90001 10C8.90001 10.6075 9.39249 11.1 10 11.1C10.6075 11.1 11.1 10.6075 11.1 10C11.1 9.50295 11.5029 9.10001 12 9.10001C12.4971 9.10001 12.9 9.50295 12.9 10C12.9 11.6016 11.6016 12.9 10 12.9C8.39838 12.9 7.10001 11.6016 7.10001 10C7.10001 9.50295 7.50295 9.10001 8.00001 9.10001Z"
      fill="#808191"
    />
    <path
      fillRule="evenodd"
      clillrule="evenodd"
      d="M4.00001 9.10001C4.49706 9.10001 4.90001 9.50295 4.90001 10C4.90001 10.6075 5.39249 11.1 6.00001 11.1C6.60752 11.1 7.10001 10.6075 7.10001 10C7.10001 9.50295 7.50295 9.10001 8.00001 9.10001C8.49706 9.10001 8.90001 9.50295 8.90001 10C8.90001 11.6016 7.60163 12.9 6.00001 12.9C4.39838 12.9 3.10001 11.6016 3.10001 10C3.10001 9.50295 3.50295 9.10001 4.00001 9.10001Z"
      fill="#808191"
    />
    <path
      fillRule="evenodd"
      clillrule="evenodd"
      d="M8.70315 3.09993C8.739 3.09996 8.77522 3.1 8.81182 3.1H15.1882C15.2248 3.1 15.261 3.09996 15.2969 3.09993C15.85 3.09935 16.3159 3.09887 16.7498 3.23745C17.1307 3.35908 17.4823 3.55816 17.7826 3.82218C18.1247 4.12298 18.364 4.52279 18.6481 4.99735C18.6665 5.02811 18.6851 5.05919 18.7039 5.09057L20.3157 7.77692C20.3249 7.79224 20.334 7.80739 20.343 7.82241C20.4863 8.06085 20.6073 8.26226 20.695 8.4829C20.7722 8.67727 20.8282 8.87944 20.862 9.08586C20.9003 9.32017 20.9002 9.55512 20.9 9.83327C20.9 9.85079 20.9 9.86848 20.9 9.88635L20.9 10H19.1L19.1 9.88635C19.1 9.52816 19.0973 9.44805 19.0856 9.37641C19.0728 9.29811 19.0515 9.22143 19.0222 9.1477C18.9954 9.08024 18.9565 9.01015 18.7722 8.70301L17.1604 6.01666C16.7899 5.39913 16.6977 5.26515 16.594 5.17393C16.4801 5.07379 16.3467 4.99827 16.2023 4.95214C16.0707 4.91011 15.9084 4.9 15.1882 4.9H8.81182C8.09166 4.9 7.92935 4.91011 7.79775 4.95214C7.65328 4.99827 7.51991 5.07379 7.40602 5.17393C7.30228 5.26515 7.2101 5.39913 6.83958 6.01666L5.22778 8.70301C5.04349 9.01015 5.00459 9.08024 4.97778 9.1477C4.94848 9.22143 4.92724 9.29811 4.91443 9.37641C4.90272 9.44805 4.90001 9.52816 4.90001 9.88635L4.90001 10H3.10001L3.10001 9.88635C3.10001 9.86848 3.1 9.85079 3.09999 9.83328C3.09984 9.55513 3.09971 9.32017 3.13804 9.08586C3.1718 8.87944 3.2278 8.67727 3.30505 8.4829C3.39274 8.26226 3.51373 8.06085 3.65697 7.82242C3.66599 7.8074 3.67509 7.79224 3.68429 7.77692L5.2961 5.09057C5.31493 5.05919 5.33353 5.02811 5.35194 4.99735C5.63601 4.52278 5.87533 4.12298 6.21742 3.82218C6.51768 3.55816 6.86929 3.35908 7.25017 3.23745C7.68411 3.09887 8.15006 3.09935 8.70315 3.09993Z"
      fill="#808191"
    />
  </svg>
);

export default PROsIcon;
