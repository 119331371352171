//-----------------Helpers---------------------------------------
import { responseHandler } from "../helpers";

//-----------------Utils---------------------------------------
import fetchRequest from "../utils/fetchRequest";

//-----------------Redux action creators---------------------------------------
import {
  approveExpertAction,
  getExpertsAction,
  removePROStatusAction,
} from "../redux/actions/expertsActions";
import { showAlertAction } from "../redux/actions/alertActions";

//-------------------Get Experts--------------------------------------

export const getExperts = (tab, initialPage) => async (dispatch, getState) => {
  dispatch(getExpertsAction({ loading: true }));

  const { expertsApprovedPage, expertsNotApprovedPage } =
    getState().expertsReducer;

  const url =
    tab === "Approved"
      ? `api/admin/expertProfiles/approve?page=${
          initialPage || expertsApprovedPage
        }`
      : `api/admin/expertProfiles/decline?page=${
          initialPage || expertsNotApprovedPage
        }`;

  const response = await fetchRequest(url, "GET");

  const error = responseHandler(response);

  dispatch(getExpertsAction({ loading: false }));

  if (!error) {
    dispatch(getExpertsAction({ experts: response.profiles, tab: tab }));
  }
};

//-------------------Approve Expert--------------------------------------

export const approveExpert = (expert, setLoading) => async (dispatch) => {
  setLoading(true);

  const response = await fetchRequest(
    `api/admin/expert/approve?id=${expert._id}`,
    "PUT"
  );

  const error = responseHandler(response);

  setLoading(false);

  if (!error) {
    dispatch(approveExpertAction({ expert: expert }));
    dispatch(showAlertAction("Expert is successfuly approved!"));
  } else dispatch(showAlertAction(error));
};

//-------------------Disapprove Expert--------------------------------------

export const disApproveExpert =
  (expert, setLoading, onSuccess) => async (dispatch) => {
    setLoading(true);

    const response = await fetchRequest(
      `api/admin/expert/decline?id=${expert._id}`,
      "PUT"
    );

    const error = responseHandler(response);

    setLoading(false);

    if (!error) {
      dispatch(removePROStatusAction({ expert: expert }));
      dispatch(showAlertAction("Expert is successfuly disapproved!"));
      onSuccess();
    } else dispatch(showAlertAction(error));
  };
