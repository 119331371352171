//---------------------Basic imports-----------------------
import React from 'react'
import './BanModal.scss'
import { Modal } from '..'

const ArchiveModal = ({
  show,
  onClose,
  onClick,
  title = 'Delete this account',
  description = 'Are you sure you want to delete this account permanently?',
  btn = 'Archive',
}) => {
  return (
    <Modal className='reviewsModal1' visible={show} onClosePopup={onClose}>
      <div className='wrapper'>
        <div>
          <div className='titleBan'>{title}</div>
          <div className='textBan'>{description}</div>
        </div>
        <div className='btnRow'>
          <div onClick={onClose} className='btn2'>
            Cancel
          </div>
          <div className='btn1' onClick={onClick}>
            {btn}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ArchiveModal
