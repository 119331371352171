//---------------------Basic imports-----------------------
import React from "react";
import "./SearchInput.scss";

//--------------------Libraries----------------------------
import clsx from "clsx";

//--------------------Assets----------------------------
import ICONS from "../../assets/icons";

const SearchInput = ({
  value,
  onChange = () => {},
  placeholder,
  name,
  type = "text",
}) => {
  //---------------------Handlers--------------------

  const changeHandler = (e) => onChange(e.target.value);

  //---------------------Layout-----------------------

  return (
    <div className={clsx("searchInput", value && "searchInput-active")}>
      <input
        type={type}
        className="searchInput__inputArea"
        name={name}
        value={value}
        onChange={changeHandler}
        placeholder={placeholder}
      />
      <div className="searchButton">{ICONS.utils.search}</div>
    </div>
  );
};

export default SearchInput;
