const UsersIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0001 13.4999C20.6885 13.4999 23.9001 14.8091 23.9001 17.7571C23.9001 19.3111 23.2564 19.9999 21.9413 19.9999L16.3454 19.9999C15.8022 19.9999 15.4184 19.449 15.5257 18.9165C15.601 18.5429 15.9146 18.2 16.2957 18.2C18.555 18.2003 22.0781 18.202 22.0781 18.202C22.0781 18.202 22.1001 17.9051 22.1001 17.7571C22.1001 16.2448 19.7822 15.2999 17.0001 15.2999C16.528 15.2999 16.0693 15.3271 15.633 15.3797C15.2978 15.4201 14.9733 15.2607 14.7834 14.9816C14.4324 14.4658 14.6173 13.6855 15.2363 13.607C15.8032 13.5351 16.3952 13.4999 17.0001 13.4999ZM7.0001 13.4999C10.6885 13.4999 13.9001 14.8091 13.9001 17.7571C13.9001 19.3111 13.2564 19.9999 11.9413 19.9999H2.05892C0.743807 19.9999 0.100098 19.3111 0.100098 17.7571C0.100098 14.8091 3.31165 13.4999 7.0001 13.4999ZM7.0001 15.2999C4.21796 15.2999 1.9001 16.2448 1.9001 17.7571C1.9001 17.9059 1.9201 18.202 1.9201 18.202H12.0781C12.0781 18.202 12.1001 17.9051 12.1001 17.7571C12.1001 16.2448 9.78224 15.2999 7.0001 15.2999ZM17.0001 4.19995C19.2928 4.19995 21.1501 6.05727 21.1501 8.34995C21.1501 10.6426 19.2928 12.5 17.0001 12.5C14.7074 12.5 12.8501 10.6426 12.8501 8.34995C12.8501 6.05727 14.7074 4.19995 17.0001 4.19995ZM7.0001 4.19995C9.29278 4.19995 11.1501 6.05727 11.1501 8.34995C11.1501 10.6426 9.29278 12.5 7.0001 12.5C4.70742 12.5 2.8501 10.6426 2.8501 8.34995C2.8501 6.05727 4.70742 4.19995 7.0001 4.19995ZM17.0001 5.99995C15.7015 5.99995 14.6501 7.05138 14.6501 8.34995C14.6501 9.64852 15.7015 10.7 17.0001 10.7C18.2987 10.7 19.3501 9.64852 19.3501 8.34995C19.3501 7.05138 18.2987 5.99995 17.0001 5.99995ZM7.0001 5.99995C5.70153 5.99995 4.6501 7.05138 4.6501 8.34995C4.6501 9.64852 5.70153 10.7 7.0001 10.7C8.29867 10.7 9.3501 9.64852 9.3501 8.34995C9.3501 7.05138 8.29867 5.99995 7.0001 5.99995Z"
      fill="#808191"
    />
  </svg>
);

export default UsersIcon;
