//---------------------Basic imports-----------------------
import React from 'react'
import './SelectInput.scss'

//---------------------Libraries-----------------------
import clsx from 'clsx'

//---------------------Assets--------------------------
import ICONS from '../../assets/icons'

const SelectInput = ({
  options = [],
  selected,
  span = '',
  onChange,
  placeholder = 'Choose',
}) => {
  //------------------Hooks---------------------------

  const [showMore, setShowMore] = React.useState(false)

  //------------------Handlers---------------------------

  const btnHandler = () => setShowMore((prev) => !prev)
  const btnHandlerData = (item) => {
    onChange(item)
    setShowMore(false)
  }

  //---------------------Layout-----------------------
  return (
    <div className={clsx('selectInput', showMore && 'selectInput-more')}>
      <button onClick={btnHandler} type='button' className='selectInput__btn'>
        <span className={span ? 'customSpan' : 'customPlaceholder'}>
          {span ? span : placeholder}
        </span>
        {ICONS.utils.smallArrow}
      </button>
      <div className='selectInput__dropdown'>
        <ul>
          {options.map((item, index) => (
            <li key={index}>
              <button
                className={clsx(selected && 'selected')}
                onClick={() => btnHandlerData(item)}
              >
                {item.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default SelectInput
