import * as yup from "yup";
import validationValues from "../values";

const editCategorySchema = yup.object().shape({
  subCategories: validationValues.editSubCategories,
  categoryTitle: validationValues.categoryTitle,
  photo: validationValues.photo,
});

export default editCategorySchema;
