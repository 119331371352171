//-----------Basic imports----------------------------
import React, { useEffect, useState } from 'react'
import styles from './Statistics.module.scss'
import { useHistory } from 'react-router-dom'
import { Button, MultiSelectInput, SelectInput } from '../../../components'
import Cart from '../../../components/Cart'
import { AdsAPI } from '../../../APIs'
import { lastAds, pageAdmin, tagsOptionsAdmin } from '../../../consts/ads'
import Delete from '../../../assets/icons/utils/delete'
import DeleteModal from '../../../components/DeleteModal'

const All = () => {
  //-----------Data--------------------------------
  const history = useHistory()
  const [ads, setAds] = useState([])
  const [category, setCategory] = useState([tagsOptionsAdmin[0].title])
  const [selectPage, setSelectPage] = useState(pageAdmin[0])
  const [lastAdsDays, setLastAdsDays] = useState(lastAds[0])
  const [totalDelete, setTotalDelete] = useState([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const selectTotal = (id) => {
    setTotalDelete(totalDelete?.filter((с) => с !== id))
    if (totalDelete?.findIndex((c) => c === id) === -1) {
      setTotalDelete((prev) => [...prev, id])
    }
  }

  const getAdsAxios = async () => {
    const hundleCategory =
      category[0] === 'All Categories' || !category.length
        ? undefined
        : category
    try {
      const response = await AdsAPI.getAds(
        selectPage.label,
        hundleCategory,
        lastAdsDays.label
      )
      setAds(response.rows)
    } catch (e) {
      console.log('e', e)
    }
  }

  const deleteAds = async () => {
    try {
      const data = {
        ids: totalDelete,
      }
      await AdsAPI.deleteAds(data)
      setTotalDelete([])
      setOpenDeleteModal(false)
      getAdsAxios()
    } catch (e) {
      console.log('e', e)
    }
  }

  useEffect(() => {
    if (category || selectPage || lastAdsDays) {
      getAdsAxios()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, selectPage, lastAdsDays])

  const handleChangeCategory = (name) => {
    const findAllCategoriesIndex = category.findIndex(
      (item) => item === 'All Categories'
    )
    if (name === 'All Categories' && findAllCategoriesIndex === -1) {
      setCategory(['All Categories'])
      return
    }
    if (name !== 'All Categories' && findAllCategoriesIndex !== -1) {
      setCategory([name])
      return
    }

    const findNameCategoryIndex = category.findIndex((item) => item === name)
    if (findNameCategoryIndex === -1) {
      setCategory([...category, name])
      return
    }

    const updatedCategory = [...category]
    updatedCategory.splice(findNameCategoryIndex, 1)
    setCategory(updatedCategory)
  }

  return (
    <>
      <div className={styles.statistics}>
        <div className={styles.statistics__top}>
          <div className={styles.statistics__total}>
            <div className={styles.statistics__totalLeft}>
              <div className={styles.searchBlock}>
                <SelectInput
                  options={lastAds}
                  placeholder='Last 30 days'
                  span={lastAdsDays.title}
                  onChange={setLastAdsDays}
                />
              </div>
              <div className={styles.searchBlock}>
                <SelectInput
                  options={pageAdmin}
                  placeholder='Home'
                  span={selectPage.title}
                  onChange={setSelectPage}
                />
              </div>
              <div className={styles.searchBlock}>
                <MultiSelectInput
                  options={tagsOptionsAdmin}
                  placeholder='All categories'
                  span={category}
                  onChange={setCategory}
                  onChangeHandle={handleChangeCategory}
                />
              </div>
              <div className={[styles.searchBlock]}>
                <Button onClick={() => history.push('/ads/create')}>
                  Create new Ad
                </Button>
              </div>
            </div>
            <div>
              {totalDelete.length ? (
                <div
                  className={'castomBtn2'}
                  onClick={() => {
                    setOpenDeleteModal(true)
                  }}
                >
                  <Delete />
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.mainContent}>
            {ads.map((item, index) => (
              <Cart
                key={index}
                item={item}
                getAdsAxios={getAdsAxios}
                selectTotal={selectTotal}
              />
            ))}
          </div>
        </div>
      </div>
      <DeleteModal
        show={openDeleteModal}
        close={() => setOpenDeleteModal(false)}
        title='Delete advertising'
        description={`Delete ${totalDelete.length} advertising?`}
        onClick={deleteAds}
      />
    </>
  )
}

export default All
