const SmallArrrowIcon = () => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00003 5.19832L1.64021 0.731804C1.21593 0.37824 0.585368 0.435564 0.231804 0.85984C-0.12176 1.28412 -0.0644362 1.91468 0.359841 2.26825L6.35984 7.26825C6.73069 7.57728 7.26936 7.57728 7.64021 7.26825L13.6402 2.26825C14.0645 1.91468 14.1218 1.28412 13.7682 0.85984C13.4147 0.435564 12.7841 0.37824 12.3598 0.731804L7.00003 5.19832Z"
      fill="#11142D"
    />
  </svg>
);

export default SmallArrrowIcon;
