//-----------Basic imports----------------------------
import React from 'react'
import ROUTES from '../../route/routes'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import All from './All'
import CreateNew from './CreateNew'
import NotFound from '../../pages/NotFound'

const Ads = () => {
  //-----------Data--------------------------------

  let { path } = useRouteMatch()
  //-----------Layout------------------------------

  return (
    <Switch>
      <Route path={path} exact component={All} />
      <Route path={`${path}/create`} exact component={CreateNew} />
      <Route path={ROUTES.notFound} component={NotFound} />
    </Switch>
  )
}

export default Ads
