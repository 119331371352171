export const AUTH_IN_PROCESS = 'AUTH_IN_PROCESS'
export const AUTH_FINISHED = 'AUTH_FINISHED'
export const AUTH_INFO = 'AUTH_INFO'
export const LOG_OUT = 'LOG_OUT_FINISHED'

export const SHOW_ALERT = 'SHOW_ALERT'

export const GET_ADMINS = 'GET_ADMINS'
export const DELETE_ADMINS = 'DELETE_ADMINS'
export const CREATE_ADMINS = 'CREATE_ADMINS'
export const SET_COUNT_ADMIN = 'SET_COUNT_ADMIN'
export const SET_PAGINATION_ADMIN = 'SET_PAGINATION_ADMIN'

export const GET_CATEGORIES = 'GET_CATEGORIES'
export const DELETE_CATEGORIES = 'DELETE_CATEGORIES'
export const CREATE_CATEGORIES = 'CREATE_CATEGORIES'
export const EDIT_CATEGORIES = 'EDIT_CATEGORIES'
export const DELETE_SUBCATEGORIES = 'DELETE_SUBCATEGORIES'
export const EDIT_SUBCATEGORIES = 'EDIT_SUBCATEGORIES'
export const CREATE_SUBCATEGORIES = 'CREATE_SUBCATEGORIES'

export const GET_EXPERTS = 'GET_EXPERTS'
export const SERCH_EXPERTS = 'SERCH_EXPERTS'
export const APPROVE_EXPERT = 'APPROVE_EXPERT'
export const REMOVE_PRO_STATUS = 'REMOVE_PRO_STATUS'
export const DELETE_EXPERT = 'DELETE_EXPERT'

export const GET_USERS = 'GET_USERS'
export const DELETE_USER = 'DELETE_USER'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_COUNT = 'SET_COUNT'
export const RESTRICT_USER = 'RESTRICT_USER'

export const DELETE_REPORT = 'DELETE_REPORT'
export const GET_REPORTS = 'GET_REPORTS'
export const SET_PAGINATION_REPORTS = 'SET_PAGINATION_REPORTS'
export const SET_COUNT_REPORTS = 'SET_COUNT_REPORTS'
export const SET_UNREAD_COUNT = 'SET_UNREAD_COUNT'
