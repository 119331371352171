const UploadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5904 2.09998C16.7583 2.09998 17.7924 2.29968 18.8666 2.87418C19.8383 3.39382 20.6062 4.16177 21.1259 5.13342C21.7004 6.20764 21.9001 7.24178 21.9001 9.40969V14.5903C21.9001 16.7582 21.7004 17.7923 21.1259 18.8665C20.6062 19.8382 19.8383 20.6061 18.8666 21.1258C17.7924 21.7003 16.7583 21.9 14.5904 21.9H9.40981C7.2419 21.9 6.20776 21.7003 5.13354 21.1258C4.16189 20.6061 3.39395 19.8382 2.8743 18.8665C2.2998 17.7923 2.1001 16.7582 2.1001 14.5903V9.40969C2.1001 7.24178 2.2998 6.20764 2.8743 5.13342C3.39395 4.16177 4.16189 3.39382 5.13354 2.87418C6.20776 2.29968 7.2419 2.09998 9.40981 2.09998H14.5904ZM14.5904 3.89998H9.40981C7.51364 3.89998 6.76022 4.04547 5.98242 4.46144C5.32445 4.81333 4.81345 5.32433 4.46156 5.9823C4.04559 6.7601 3.9001 7.51351 3.9001 9.40969V14.5903C3.9001 16.4864 4.04559 17.2398 4.46156 18.0177C4.81345 18.6756 5.32445 19.1866 5.98242 19.5385C6.76022 19.9545 7.51364 20.1 9.40981 20.1H14.5904C16.4866 20.1 17.24 19.9545 18.0178 19.5385C18.6757 19.1866 19.1867 18.6756 19.5386 18.0177C19.9546 17.2398 20.1001 16.4864 20.1001 14.5903V9.40969C20.1001 7.51351 19.9546 6.7601 19.5386 5.9823C19.1867 5.32433 18.6757 4.81333 18.0178 4.46144C17.24 4.04547 16.4866 3.89998 14.5904 3.89998ZM12.0001 7.49998C12.4589 7.49998 12.8375 7.84331 12.8931 8.28708L12.9001 8.39998L12.8991 11.1H15.6001C16.0589 11.1 16.4375 11.4433 16.4931 11.8871L16.5001 12C16.5001 12.4588 16.1568 12.8374 15.713 12.893L15.6001 12.9H12.8991L12.9001 15.6C12.9001 16.0588 12.5568 16.4374 12.113 16.493L12.0001 16.5C11.5413 16.5 11.1626 16.1566 11.1071 15.7129L11.1001 15.6L11.0991 12.9H8.4001C7.94128 12.9 7.56265 12.5566 7.50711 12.1129L7.5001 12C7.5001 11.5412 7.84343 11.1625 8.2872 11.107L8.4001 11.1H11.0991L11.1001 8.39998C11.1001 7.94115 11.4434 7.56252 11.8872 7.50699L12.0001 7.49998Z"
      fill="white"
    />
  </svg>
);

export default UploadIcon;
