import {
  AUTH_IN_PROCESS,
  AUTH_FINISHED,
  LOG_OUT,
  AUTH_INFO,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  userName: "",
  password: "",
  logOut: false,
  name: "",
  email: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_IN_PROCESS:
      return { ...state, loading: true, ...action.payload };

    case AUTH_FINISHED:
      return { ...state, ...action.payload, loading: false };
    case AUTH_INFO:
      return { ...state, name: action.payload };

    case LOG_OUT:
      return {};
    default:
      return state;
  }
};

export default authReducer;
