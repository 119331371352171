const PaginationLeft = (color = '#404247') => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.29 8.28996L19.71 9.70996L13.41 16L19.71 22.29L18.29 23.71L10.59 16L18.29 8.28996V8.28996Z'
      fill={color}
    />
  </svg>
)

export default PaginationLeft
