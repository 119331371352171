//---------------------Basic imports-----------------------
import React from 'react'
import './BanModal.scss'
import { Modal } from '..'

const DeleteModal = ({
  show,
  close,
  onClick,
  title = 'Delete this account',
  description = 'Are you sure you want to delete this account permanently?',
}) => {
  return (
    <Modal className='reviewsModal1' visible={show} onClosePopup={close}>
      <div className='wrapper'>
        <div>
          <div className='titleBan'>{title}</div>
          <div className='textBan'>{description}</div>
        </div>
        <div className='btnRow'>
          <div onClick={close} className='btn2'>
            Cancel
          </div>
          <div className='btn1' onClick={onClick}>
            Delete
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
