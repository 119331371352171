import store from '..'
import { refreshToken } from '../APIs/refreshToken'
import { API_ENDPOINT } from '../consts/urls'
import { showAlertAction } from '../redux/actions/alertActions'

const fetchRequest = async (url, method, body, formData = false, Token) => {
  let tokenn =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0NDI1ZmEyZDNjMDg2YWJmYjRjMzY3NyIsImlhdCI6MTY4MjA4Nzk2M30.MI3EwaS9UndGFAzZiAnZ9xXX1lYAw61VjTP-rULAkOc'
  let token = Token || tokenn
  // let token = Token || localStorage.getItem('accessToken')
  console.log('token', token)

  let headers = {}

  try {
    if (formData === true) {
      headers = {
        'Content-Type': 'multipart/form-data',
      }
    } else
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }

    if (token) headers.Authorization = `Bearer ${token}`

    const response = await fetch(`${API_ENDPOINT}/${url}`, {
      method: method.toUpperCase(),
      headers: headers,
      body: formData === true ? body : JSON.stringify(body),
    })

    console.log(response)

    if (response.status === (200 || 201)) {
      const data = await response.json()
      return { ...data, status: response.status }
    } else if (response.status === 400) {
      const data = await response.json()
      return { ...data, status: response.status }
    } else if (response.status === 401) {
      if (localStorage.getItem('accessToken')) {
        const refreshed = await refreshToken()
        if (refreshed === true) {
          const newResponse = fetchRequest(url, method, body)
          return newResponse
        } else {
          localStorage.removeItem('accessToken')
          store.dispatch(
            showAlertAction(
              'Time of your workin session is out, please login again.'
            )
          )
        }
      }
    } else if (response.status === (403 || 404)) {
      const data = await response.json()
      return { ...data, status: response.status }
    } else if (response.status === 409) {
      const data = await response.json()
      return { ...data, status: response.status }
    } else if (response.status === 500) {
      console.log(await response.json())
      alert('Sorry the server is not available right now please try later')
    }
  } catch (e) {
    console.log(e)
  }
}

export default fetchRequest
