//-----------Basic imports----------------------------
import React, { useEffect } from "react";
import styles from "./Home.module.scss";

//-----------Libraries----------------------------
import {
  NavLink,
  Route,
  useLocation,
  Switch,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

//-----------Dashboard pages---------------------------
import { Admins, Ads, Reports, Statistics, Users } from "../../newDashbord";

//----------Components----------------------------------------------
import { Loader } from "../../components";

//-----------Assets----------------------------
import ICONS from "../../assets/icons";

//-----------Routes----------------------------
import ROUTES from "../../route/routes";

//-------------Helpers----------------------------
import { renderPageTitle } from "../../helpers";
import { deleteToken } from "../../utils/localStorage";
import UserInformation from "../../components/UserInformation";
import NotFound from "../NotFound";
import ReportsAPI from "../../APIs/reports";
import { SET_UNREAD_COUNT } from "../../redux/actions/actionTypes";

const Home = () => {
  //-----------Hooks----------------------------
  const location = useLocation();
  const history = useHistory();

  const [state, setState] = React.useState({
    showNavBar: false,
  });

  const dispatch = useDispatch();

  const { showNavBar } = state;

  const { loading } = useSelector((state) => state.authReducer);
  const { badge } = useSelector((state) => state.reportsReducer);

  //-----------Data----------------------------
  const name = useSelector((state) => state.authReducer);

  const tabs = [
    {
      name: "Manage Users",
      path: ROUTES.users.page,
      icon: ICONS.navBar.statistics,
    },
    {
      name: "Reports",
      path: ROUTES.reports,
      icon: ICONS.navBar.users,
    },
    {
      name: "Manage Ads",
      path: ROUTES.ads,
      icon: ICONS.navBar.PROs,
    },
    // {
    //   name: 'Statistics',
    //   icon: ICONS.navBar.categories,
    //   path: ROUTES.statistics.page,
    // },
    {
      name: "Settings",
      icon: ICONS.navBar.admins,
      path: ROUTES.admins.page,
    },
  ];

  //-----------Handlers----------------------------

  const menuClickHandler = () =>
    setState((prev) => ({ ...prev, showNavBar: !prev.showNavBar }));

  const tabClickHandler = () =>
    setState((prev) => ({ ...prev, showNavBar: false }));

  const logout = async () => {
    await deleteToken();
    window.localStorage.clear();
    history.push(ROUTES.signIn);
  };

  const getBadge = async () => {
    try {
      const res = await ReportsAPI.getReportsBadge();
      dispatch({ type: SET_UNREAD_COUNT, payload: res });
    } catch (e) {
      console.log("e", e);
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      history.push(ROUTES.users.all);
    }
    getBadge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.home}>
      <div className={styles.home__box}>
        <div
          className={clsx(
            styles.home__menu,
            showNavBar && styles.home__menu_active
          )}
        >
          <div className={styles.home__logo}> </div>
          <div className={styles.home__menuLogo}>Admin tools</div>
          <ul>
            {tabs.map((tab, index) => (
              <li
                className={clsx(tab.name === "Admins" && "admins")}
                onClick={tabClickHandler}
                key={index}
              >
                {tab.name === "Reports" && badge > 0 && (
                  <div className={styles.badge}>{badge}</div>
                )}
                {tab?.path ? (
                  <NavLink to={tab.path} activeClassName={styles.active}>
                    {tab.icon}
                    {tab.name}
                  </NavLink>
                ) : (
                  <span>
                    {tab.icon}
                    {tab.name}
                  </span>
                )}
              </li>
            ))}
          </ul>
          <div className={styles.home__menuFooter}>
            <div className={styles.home__menuLogout} onClick={logout}>
              {loading ? (
                <div className={styles.home__menuLoading}>
                  <Loader />
                </div>
              ) : (
                <div>
                  <span>
                    {ICONS.navBar.logOut}
                    Log out
                  </span>
                </div>
              )}
            </div>
            <div className={styles.home__menuInfo}>
              <span className={styles.home__menuUserrole}> {name.name}</span>
            </div>
          </div>
        </div>

        <div className={styles.home__right}>
          <div className={styles.home__header}>
            <button
              onClick={menuClickHandler}
              className={clsx(
                styles.home__menuIcon,
                state.showNavBar && styles.home__menuIcon_active
              )}
            >
              <div></div>
              <div></div>
              <div></div>
            </button>
            <div>
              <h2 className={styles.home__title}>
                {renderPageTitle(location.pathname)}
              </h2>
            </div>
          </div>

          <Switch>
            <Route path={ROUTES.statistics.page} component={Statistics} />
            <Route path={ROUTES.admins.page} component={Admins} />
            <Route path={ROUTES.users.id} component={UserInformation} />
            <Route path={ROUTES.users.page} component={Users} />
            <Route path={ROUTES.reports} component={Reports} />
            <Route path={ROUTES.ads} component={Ads} />
            <Route path={ROUTES.notFound} component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Home;
