import {
  CREATE_ADMINS,
  DELETE_ADMINS,
  GET_ADMINS,
  SET_PAGINATION_ADMIN,
  SET_COUNT_ADMIN,
} from '../actions/actionTypes'

const initialSatate = {
  admins: [],
  pagination: 1,
  count: 1,
  success: false,
  adminsPage: 1,
  loadMoreAdmins: true,
}

const adminsReducer = (state = initialSatate, action) => {
  switch (action.type) {
    //------------------Create admins--------------------------------------------

    case CREATE_ADMINS: {
      return { ...state, admins: [...state.admins, action.payload] }
    }

    //------------------Delete admins--------------------------------------------

    case DELETE_ADMINS: {
      // const { _id } = action.payload

      // let newState

      // if (_id) {
      //   const newAdmins = state.admins.filter((item) => item._id !== _id)

      //   newState = {
      //     ...state,
      //     admins: newAdmins,
      //   }
      // } else newState = state

      return {
        ...state,
        admins: state.admins.filter((item) => item.id !== action.payload),
      }
    }

    //------------------Get admins--------------------------------------------

    case GET_ADMINS: {
      // const { admins } = action.payload

      // if (admins) {
      //   return {
      //     ...state,
      //     admins:
      //       state.adminsPage === 1 ? admins : [...state.admins, ...admins],
      //     adminsPage: 1 + state.adminsPage,
      //     loadMoreAdmins: admins.length > 0,
      //   }
      // }
      return { ...state, admins: action.payload }
    }

    case SET_PAGINATION_ADMIN: {
      return { ...state, pagination: action.payload }
    }
    case SET_COUNT_ADMIN: {
      return { ...state, count: action.payload }
    }

    default:
      return state
  }
}

export default adminsReducer
