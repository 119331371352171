//---------------------Basic imports-----------------------
import React, { useState } from 'react'
import styles from './SignIn.module.scss'

//----------------------Libraries---------------------------
import { Formik } from 'formik'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import VALIDATIONS from '../../validation'
//----------------------Components--------------------------
import { Input, Button, Loader } from '../../components'

//----------------------Auth--------------------------
import AuthApi from '../../APIs/Auth'
import ROUTES from '../../route/routes'
import { setToken } from '../../utils/localStorage'

const SignIn = () => {
  //---------------------Hooks-----------------------

  const { loading } = useSelector((state) => state.authReducer)
  const history = useHistory()
  const [mainError, setMainError] = useState('')

  //---------------------Btn handlers-----------------------

  const submitHandler = async (value) => {
    const data = {
      email: value.email,
      password: value.password,
    }
    try {
      const res = await AuthApi.auth(data)
      setToken(res.token)
      history.push(ROUTES.users.all)
    } catch (err) {
      console.log('err', err)
      setMainError(err.response.data.error)
    }
  }

  return (
    <div className={styles.signIn}>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{ email: '', password: '' }}
        validationSchema={VALIDATIONS.loginSchema}
        onSubmit={submitHandler}
      >
        {({ errors, values, handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit} className={styles.signIn__inner}>
            <div style={{ justifyContent: 'center', width: '100%' }}>
              <div className={styles.signIn__logo}></div>
            </div>

            <div className={styles.signIn__form}>
              <Input
                label='Email'
                formHelper
                placeholder='Enter email or e-mail'
                name='email'
                value={values.email}
                error={errors.email}
                onChange={(value) => {
                  handleChange('email')(value)
                  delete errors.email
                  setMainError('')
                }}
                handleDelete
              />
              <Input
                label='Password'
                formHelper
                placeholder='Enter password'
                name='password'
                type='password'
                value={values.password}
                error={errors.password}
                onChange={(value) => {
                  handleChange('password')(value)
                  delete errors.password
                  setMainError('')
                }}
                handleDelete
              />
              {loading ? (
                <div className={styles.signIn__loader}>
                  <Loader />
                </div>
              ) : (
                // <Link to={ROUTES.home}>
                <div className={styles.signIn__btnContainer}>
                  <p className={styles.signIn__mainError}>{mainError}</p>
                  <Button onClick={handleSubmit}>
                    <span>Log In</span>
                  </Button>
                </div>
                // </Link>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default SignIn
