//---------------------Basic imports-----------------------
import React from "react";
import "./PROStatusModal.scss";

//---------------------Components-----------------------
import { Button, Loader, Modal } from "..";

//---------------------Libraries-----------------------
import { useDispatch } from "react-redux";
import { disApproveExpert } from "../../APIs/experts";

const PROStatusModal = ({ show, close, expert }) => {
  //---------------------Hooks-----------------------

  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  //---------------------Handlers-----------------------

  const removeBtnHandler = () =>
    dispatch(disApproveExpert(expert, setLoading, close));

  //---------------------Layout-----------------------

  return (
    <Modal className="PROstatusModal" visible={show} onClosePopup={close}>
      <h2>This user has a Pro account</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pulvinar ac
        nibh iaculis et nunc lectus. Platea semper nisl feugiat.
      </p>
      <div className="PROstatusModal__controls">
        {loading ? (
          <div className="PROstatusModal__loader">
            <Loader />
          </div>
        ) : (
          <>
            <Button onClick={close}>
              <span>Go Back</span>
            </Button>
            <Button onClick={removeBtnHandler}>
              <span>Remove Pro</span>
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default PROStatusModal;
