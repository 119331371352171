//-----------Basic imports----------------------------
import React, { useEffect } from 'react'
import styles from './AllUsers.module.scss'

//-----------Libraries----------------------------
import { useSelector, useDispatch } from 'react-redux'

//------------Components-----------------------------
import { UsersCard } from '../../../components'

import UsersAPI from '../../../APIs/users'
import { GET_USERS, SET_COUNT } from '../../../redux/actions/actionTypes'

const AllUsers = () => {
  const { search } = useSelector((state) => state.expertsReducer)
  const { users, pagination } = useSelector((state) => state.usersReducer)
  const dispatch = useDispatch()

  const getUsers = async () => {
    const res = await UsersAPI.getUsers(search, pagination)
    dispatch({ type: GET_USERS, payload: res.rows })
    dispatch({ type: SET_COUNT, payload: res.count })
  }

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pagination])

  return (
    <div className={styles.allPROs}>
      <ul>
        {users.map((item) => (
          <UsersCard key={item.id} data={item} getUsers={getUsers} />
        ))}
      </ul>
    </div>
  )
}

export default AllUsers
